import {
    Redirect,
    // BrowserRouter as Router,
    // Switch,
    Route, Switch, useLocation, useRouteMatch,
    // Redirect,
} from "react-router-dom";

import { useMe } from "../providers/Me"
import { useQuery, gql, useMutation } from "@apollo/client"
import { Loading, MyTextField, AppBarButton, MyLink } from "./Widgets"
import { useState, useEffect } from "react"

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import InfoIcon from '@mui/icons-material/Info';
import LaunchIcon from '@mui/icons-material/Launch';

import DownloadIcon from '@mui/icons-material/Download';

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import {
    Stack,
    Container,
    Card,
    CardContent,
    TablePagination,
    Typography,
    Box,
    Tab,
    Tabs,
    Button,
    Checkbox,
    Link,
    MenuItem,
    FormGroup,
    FormControlLabel,
    Alert,
    InputAdornment,
    IconButton,
    Paper,
    Badge,
    AppBar,
    Toolbar,
    Grid,
    Divider,
} from '@mui/material'
import { useDebounce, useLocalStorage, useStateFilters } from "../Hooks"
import { useParams, useHistory } from "react-router-dom"
import { ActivityGraph, ActivityGraphLegend } from "./ActivityGraph"
import { CreatePatientQuery } from "./Queries";
// import { PatientQueryListing } from "./Queries"

import { HIDDEN_ATTRIBUTES, Settings } from '../Settings'
import { CreateSDV } from "./SDV";

const DISPLAY_SCREENING_STATES = {
    "Pending": ["Pending"],
    "Failed": ["Failed"],
    "Included": ["Included", "Included MS2 Pending", "Included MS2 Yes", "Included MS2 No"],
}

export const PatientListing = () => {
    const { me } = useMe()
    const history = useHistory();
    const [searchTerm, setSearchTerm] = useState("")
    const debouncedSearchTerm = useDebounce(searchTerm, 350)
    const [searchArgs, setSearchArgs] = useState({
        q: debouncedSearchTerm,
        skip: 0,
        first: 25,
    })

    const [stateFilter, toggleStateFilter] = useStateFilters([])

    const { data, error, loading } = useQuery(gql`
    query PatientListing ( $searchArgs: SearchPatientInput!, $stateFilter: [String!]! ) {
        patients ( searchArgs: $searchArgs, stateFilter: $stateFilter ) {
            page
            count
            results {
                id
                attrs
                integrateIdentifier
                registrationDate
                treatingInvestigator {
                    id
                    title
                    firstName
                    lastName
                }
                site {
                    id
                    fullName
                    shortName
                    breastCode
                }
            }
        }
    }
    `, {
        variables: { searchArgs, stateFilter },
        fetchPolicy: 'no-cache',
    })

    useEffect(() => {
        setSearchArgs(s => ({ ...s, q: debouncedSearchTerm, skip: 0 }))
    }, [debouncedSearchTerm])

    if (error) return <>{JSON.stringify(error, null, 2)}</>;

    const patients = data?.patients
    const results = patients?.results

    const showSiteDetails = Boolean(me && (!(me?.isInvestigator || me?.isNurse)))

    const searchLabel = (me?.isMonitor)
        ? `Search for a patient by ID, treating investigator, or site code`
        : `Search for a patient by ID or treating investigator`

    return (
        <>
            <Container>
                <Card>
                    <CardContent>
                        <Stack direction="column" spacing={2}>
                            <MyTextField
                                label={searchLabel}
                                value={searchTerm}
                                onChange={e => {
                                    setSearchTerm(e.target.value)
                                }}
                                autoFocus
                            />

                            <FormGroup row sx={{ mb: 10 }}>
                                {Object.keys(DISPLAY_SCREENING_STATES).map(s => (
                                    <FormControlLabel key={s} control={
                                        <Checkbox
                                            onChange={() => {
                                                DISPLAY_SCREENING_STATES[s].forEach(realState => {
                                                    toggleStateFilter(realState)
                                                })
                                            }}
                                            color="primary"
                                            checked={stateFilter.indexOf(s) > -1}
                                        />
                                    }
                                        labelPlacement="end"
                                        label={s}
                                    />
                                ))}
                            </FormGroup>

                            {loading
                                ? (<Loading />)
                                : (
                                    <>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Patient ID</TableCell>
                                                    <TableCell>Registration Date</TableCell>
                                                    {showSiteDetails && (
                                                        <>
                                                            <TableCell>Site</TableCell>
                                                        </>
                                                    )}
                                                    <TableCell>Investigator</TableCell>
                                                    <TableCell>Bone-only</TableCell>
                                                    <TableCell>In follow-up</TableCell>
                                                    <TableCell>Status</TableCell>
                                                    {me?.isMonitor && (
                                                        <TableCell>Ineligible</TableCell>
                                                    )}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {results.map(p => (
                                                    <TableRow key={p.id} hover sx={{ cursor: 'pointer' }} onClick={() => {
                                                        // @TOTO this is where I should add the "confirm checksum" popup screen?
                                                        // something that saves the "currently opened patient" (ssid, checksum) into
                                                        // a local storage session, or similar could be cool.

                                                        // or multiple, maybe?
                                                        history.push(`/patient/${p.integrateIdentifier}/summary`)
                                                    }}>
                                                        <TableCell>{p.integrateIdentifier}</TableCell>
                                                        <TableCell>{p.registrationDate}</TableCell>
                                                        {showSiteDetails && (
                                                            <>
                                                                <TableCell>{p.site.breastCode}</TableCell>
                                                            </>
                                                        )}
                                                        <TableCell>
                                                            {Boolean(p.treatingInvestigator)
                                                                ? <>{p.treatingInvestigator.title} {p.treatingInvestigator.firstName} {p.treatingInvestigator.lastName}</>
                                                                : <>N/A</>
                                                            }
                                                        </TableCell>
                                                        <TableCell>{p.attrs.authorised_bone_only || 'False'}</TableCell>
                                                        <TableCell>{p.attrs.in_follow_up}</TableCell>
                                                        <TableCell>{p.attrs.screening_status}</TableCell>
                                                        {me?.isMonitor && (
                                                            <TableCell>{
                                                                p.attrs.ineligible_for_analysis === ''
                                                                ? <Typography><Box color="text.disabled">&mdash;</Box></Typography>
                                                                : p.attrs.ineligible_for_analysis
                                                            }
                                                            </TableCell>
                                                        )}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        <TablePagination
                                            component="div"
                                            count={patients.count}
                                            page={patients.page}
                                            rowsPerPage={searchArgs.first}
                                            rowsPerPageOptions={[10, 25, 50, 100, { value: -1, label: "ALL" }]}
                                            onPageChange={(e, newPage) => {
                                                setSearchArgs({ ...searchArgs, skip: newPage * searchArgs.first })
                                            }}
                                            onRowsPerPageChange={e => {
                                                setSearchArgs({ ...searchArgs, first: e.target.value })
                                            }}
                                        />
                                    </>
                                )
                            }
                        </Stack>
                    </CardContent>
                </Card>
            </Container>
        </>
    )
}


export const QueryCreatorComponent = ({ attributevalueSet }) => {
    const [tabIndex, setTabIndex] = useState(0)
    const activityIds = new Set([])
    const activities = attributevalueSet.map(av => av.attribute.activity).filter(act => {
        if (activityIds.has(act.id)) return false;
        if (!act.siteActivity) {
            return false;
        }
        activityIds.add(act.id)
        return true;
    })

    activities.sort((a, b) => a.presentationOrder - b.presentationOrder)

    const activeAttributeValues = attributevalueSet
        .filter(av => activities[tabIndex].id === av.attribute.activity.id)
        .filter(av => av.attribute.computed === false)
        .filter(av => av.value !== null && av.value !== "")

    activeAttributeValues.sort((a, b) => a.presentationOrder - b.presentationOrder)

    const [selectedAttributes, setSelectedAttributes] = useState([])

    const toggleSelectedAttribute = id => {
        if (selectedAttributes.indexOf(id) >= 0) {
            setSelectedAttributes(selectedAttributes.filter(avId => id !== avId))
        } else {
            setSelectedAttributes([...selectedAttributes, id])
        }
    }

    return (
        <>
            <Card sx={{ mt: 2 }}>
                <CardContent>
                    <Box sx={{ flexGrow: 1, display: 'flex', minHeight: 600 }}>
                        <Tabs
                            orientation="vertical"
                            variant="scrollable"
                            value={tabIndex}
                            onChange={(event, newValue) => {
                                setTabIndex(newValue)
                            }}
                        >
                            {activities.map(a => (
                                <Tab key={a.id} label={a.displayName} sx={{
                                    textAlign: 'left',
                                    // justifyContent: 'flex-start',
                                    textTransform: 'none',
                                    alignItems: 'flex-start',
                                }} />
                            ))}
                        </Tabs>

                        <Box px={4} sx={{ width: '100%' }}>
                            <Table sx={{ width: '100%' }} size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>Attribute</TableCell>
                                        <TableCell>Value</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {activeAttributeValues.map(av => (
                                        <TableRow key={av.id} hover sx={{ cursor: 'pointer' }} onClick={() => toggleSelectedAttribute(av.id)}>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    checked={selectedAttributes.indexOf(av.id) >= 0}
                                                    onChange={() => toggleSelectedAttribute(av.id)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="body1">{av.attribute.displayName}</Typography>
                                            </TableCell>
                                            <TableCell>{av.value}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </>
    )
}



export const PatientSummary = props => {
    const { me } = useMe();
    const { ssid } = useParams()
    const history = useHistory();

    const { data, loading, error, refetch } = useQuery(gql`
        query PatientSummaryQuery ( $ssid: String!, $attributes: [String!]! ) {
            patient ( ssid: $ssid, attributes: $attributes ) {
                id
                integrateIdentifier
                integrateChecksum
                registrationDate
                attrs
                aggregatedScreeningFailureReasons

                consistencyIssues

                downloadLinks {
                    reportAutoPdfFile
                    reportAutoJsonFile
                    reportMabPdfFile
                }

                cohorts {
                    name
                    displayName
                    value
                }

                missingSdvCount

                treatingInvestigator {
                    id
                    title
                    lastName
                }
                site {
                    id
                    fullName
                }
                attributevalueSet {
                    id
                    attribute {
                        id
                        name
                        displayName
                        computed
                        presentationOrder
                        activity {
                            id
                            name
                            presentationOrder
                            displayName
                            siteActivity
                        }
                    }
                    value
                }
            }
        }
    `, {
        variables: {
            ssid,
            attributes: [
                'in_follow_up',
                'screening_status',
                'authorised_bone_only',
                'gender',
                'dob',
                'kit_number',
                'icf_signature_date',
                'icf_version_signed',

                'icf_1_0_signed',
                'icf_1_1_signed',
                'icf_2_0_signed',
                'icf_3_0_signed',
                'icf_4_0_signed',

                'icf_1_0_date',
                'icf_1_1_date',
                'icf_2_0_date',
                'icf_3_0_date',
                'icf_4_0_date',

                'ineligible_for_analysis',
                'ineligible_for_analysis_reason',
            ]
        },
        fetchPolicy: 'no-cache',
    })

    const { url, path } = useRouteMatch();

    if (loading) return <Loading />
    if (error) return 'error'
    const { patient } = data
    const { site } = patient

    const patientIsInCohort = patient.cohorts
        .filter(cohort => cohort.value === true)
        .length > 0;


    const hasAutoReport = Boolean(patient.downloadLinks.reportAutoPdfFile)
    const hasMabReport = Boolean(patient.downloadLinks.reportMabPdfFile)
    const hasReport = hasAutoReport || hasMabReport

    return (
        <>
            <Container>
                <Card>
                    <CardContent>
                        <Stack direction="column">
                            <Typography variant="h5">
                                {`Patient ${ssid}`}
                                {(me.isMonitor || me.isGroupMonitor) && (
                                    <Box component="span" sx={{ color: 'text.disabled' }}>{` | code ${patient.integrateChecksum.toUpperCase()}`}</Box>
                                )}
                            </Typography>
                            <Typography variant="body1">Registered {patient.registrationDate} by {Boolean(patient.treatingInvestigator)
                                ? <>{patient.treatingInvestigator.title} {patient.treatingInvestigator.lastName}</>
                                : <>N/A</>
                            }
                            </Typography>
                            <Typography variant="body2" color="text.secondary">{site.fullName}</Typography>
                            {hasReport && (
                                <Stack direction="row" spacing={2} mt={2}>
                                    {(hasAutoReport && !hasMabReport) && (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                window.open(Settings.BACKEND_URL + patient.downloadLinks.reportAutoPdfFile, '_blank')
                                            }}
                                            endIcon={<DownloadIcon />}
                                            >
                                            Automated report
                                        </Button>
                                    )}
                                    {hasMabReport && (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                window.open(Settings.BACKEND_URL + patient.downloadLinks.reportMabPdfFile, '_blank')
                                            }}
                                            endIcon={<DownloadIcon />}
                                            >
                                            MAB report
                                        </Button>
                                    )}
                                </Stack>
                            )}
                        </Stack>
                    </CardContent>
                </Card>

                <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                    <Card sx={{ width: '100%' }}>
                        <CardContent>
                            <Stack direction="column" spacing={2}>
                                <div>
                                    <Typography variant="h6">{patient.attrs.gender || "N/A"}</Typography>
                                    <Typography variant="body1" color="text.secondary">Gender</Typography>
                                </div>
                                <div>
                                    <Typography variant="h6">{patient.attrs.dob || "N/A"}</Typography>
                                    <Typography variant="body1" color="text.secondary">Date of birth</Typography>
                                </div>
                            </Stack>
                        </CardContent>
                    </Card>
                    <Card sx={{ width: '100%' }}>
                        <CardContent>
                            <Stack direction="column" spacing={2}>
                                {patient.attrs.kit_number && (
                                    <div>
                                        <Typography variant="h6">{patient.attrs.kit_number || 'unknown'}</Typography>
                                        <Typography variant="body1" color="text.secondary">Kit number</Typography>
                                    </div>
                                )}

                                {["1_0", "1_1", "2_0", "3_0", "4_0"].map(icf_version => {
                                    const key = `icf_${icf_version}_signed`;
                                    const date_key = `icf_${icf_version}_date`;
                                    const prettyIcf = 'v' + icf_version.replace('_', '.');
                                    const activityName = `icf_${icf_version}`
                                    const activityLink = `/patient/${ssid}/activity/${activityName}`;
                                    return (patient.attrs[key] !== 'True')
                                        ? null
                                        : <>
                                              <div key={key}>
                                                  <Typography variant="h6">ICF {prettyIcf}</Typography>
                                                  <Typography variant="body1" color="text.secondary">
                                                      Date: <span style={{ whiteSpace: 'nowrap' }}>{patient.attrs[date_key]}</span>
                                                      {(me?.isInvestigator || me?.isNurse) && (
                                                          <>
                                                              <br />
                                                              <Link href={activityLink} sx={{ textDecoration: 'none' }}>&#9998; edit</Link>
                                                          </>
                                                      )}
                                                  </Typography>
                                              </div>
                                          </>
                                })}
                            </Stack>
                        </CardContent>
                    </Card>
                    <Card sx={{ width: '100%' }}>
                        <CardContent>
                            <Stack direction="column" spacing={2}>

                                <div>
                                    <Typography variant="h6">{patient.attrs.screening_status}</Typography>
                                    <Typography variant="body1" color="text.secondary">Screening status</Typography>
                                </div>

                                {((patient.attrs.screening_status === 'Failed') && (patient.aggregatedScreeningFailureReasons.length > 0)) && (
                                    <>
                                        <Divider orientation="horizontal" flexItem />
                                        <div>
                                            <Typography variant="h6">Additional notes</Typography>
                                            {patient.aggregatedScreeningFailureReasons.map((note, i) => (
                                                <Typography key={i} variant="body1">{note}</Typography>
                                            ))}
                                        </div>
                                    </>
                                )}
                                
                                {patient.attrs.ineligible_for_analysis === "Yes" && (
                                    <>
                                        <Divider orientation="horizontal" flexItem />
                                        <div>
                                            <Typography variant="h6">Ineligible for analysis</Typography>
                                            <Typography variant="body1" color="text.secondary">{patient.attrs.ineligible_for_analysis_reason}</Typography>
                                        </div>
                                    </>
                                )}
                            </Stack>
                        </CardContent>
                    </Card>
                </Stack>

                {patientIsInCohort && (
                    <Card sx={{ mt: 2, p: 2 }}>
                        <Typography>AURORA 2.0 Cohorts</Typography>
                        <ul>
                            {patient.cohorts.filter(cohort => cohort.value).map(cohort => (
                                <li><Typography>{cohort.displayName}</Typography></li>
                            ))}
                        </ul>
                    </Card>
                )}

                {(patient.consistencyIssues.length > 0) && (
                    <>
                        {patient.consistencyIssues.map(i => (
                            <Alert sx={{ mt: 1 }} severity="warning"><span dangerouslySetInnerHTML={{ __html: i }} /></Alert>
                        ))}
                    </>
                )}


                <Card sx={{ mt: 2, p: 2 }}>
                    <Stack direction="row" spacing={1}>
                        <AppBarButton
                            to={`${url}/graph`}
                            label="Activity graph" />

                        {(me?.isInvestigator || me?.isNurse) && (
                            <>
                                <Divider orientation="vertical" flexItem />
                                <AppBarButton
                                    to={`/patient/${ssid}/activity/report_issue_at_site`}
                                    label={`Report issue`} />
                            </>
                        )}

                        {(me?.isLabMember) && (
                            <>
                                <Divider orientation="vertical" flexItem />
                                <AppBarButton
                                    to={`/patient/${ssid}/activity/report_issue_at_lab`}
                                    label={`Report issue`} />
                            </>
                        )}

                        {(me?.isDeveloper || me?.isMedicalReviewer) && (
                            <>
                                <Divider orientation="vertical" flexItem />
                                <AppBarButton
                                    to={`/patient/${ssid}/activity/medical_review`}
                                    label={`Medical Review`} />
                            </>
                        )}
                    </Stack>
                </Card>

                <Switch>
                    <Route exact path={`${url}/graph`}>
                        <>
                            <Card sx={{ pt: 2, mt: 2 }}>
                                <CardContent>
                                    <ActivityGraph ssid={ssid} />
                                </CardContent>
                            </Card>
                            <ActivityGraphLegend />

                            {(me?.isGroupMonitor || me?.isMonitor) && (
                                <CreatePatientQuery ssid={ssid} />
                            )}
                        </>
                    </Route>

                    {/** 
                    <Route exact path={`${path}/create_query`}>
                        <CreatePatientQuery ssid={ssid} />
                    </Route>
                    */}

                    <Route exact path={`${path}/sdv`}>
                        <CreateSDV ssid={ssid} onChange={() => refetch()} />
                    </Route>

                    <Route exact path={path}><Redirect to={`${url}/graph`} /></Route>
                </Switch>
            </Container>
        </>
    )
}

export const PatientActivityComponent = () => {
    const { ssid, activityName } = useParams()
    const history = useHistory()

    // dict, mapping Attribute name => AV value
    const [values, setValues] = useState({})

    const [cachedDataCapture, setCachedDataCapture] = useState({
        fields: [],
        values: {},
        activity: {},
        blockingCauses: [],
        isUpdate: false,
        virtualFieldErrors: [],
    })

    const { data, loading: sdvWarningLoading } = useQuery(gql`
        query SDVWarningQuery ( $ssid: String!, $activityName: String! ) {
            patientActivity ( ssid: $ssid, activityName: $activityName ) {
                id
            }
        }
    `, {
        variables: {
            ssid,
            activityName,
        }
    })

    const [newDataCapture, { loading }] = useMutation(gql`
        mutation NewDataCaptureMutation ( $ssid: String!, $activityName: String! $submittedValues: GenericScalar!, $submit: Boolean ) {
            newDataCapture ( ssid: $ssid, activityName: $activityName, submittedValues: $submittedValues, submit: $submit ) {
                __typename
                hasSubmitErrors
                hasErrors
                hasChanges
                values
                isUpdate
                virtualFieldErrors {
                    fieldName
                    message
                }
                activity {
                    id
                    name
                    displayName
                }
                blockingCauses
                fields {
                    value
                    errors
                    attribute {
                        id
                        name
                        description
                        displayName
                        dataType
                        sdvRequirement
                        minValue
                        maxValue
                        required
                        computed
                        stringEnum {
                            id
                            attributestringenumitemSet {
                                id
                                value
                                presentationOrder
                            }
                        }
                    }
                }
            }
        }
    `, {
        onCompleted: (data) => {
            setCachedDataCapture({ ...data.newDataCapture })
        },
    })

    const { fields, activity, blockingCauses, isUpdate, hasSubmitErrors, virtualFieldErrors } = cachedDataCapture

    useEffect(() => {
        newDataCapture({
            variables: {
                ssid,
                activityName,
                submittedValues: {},
                submit: false,
            }
        }).then(({ data }) => {
            setValues(data.newDataCapture.values)
        })
    }, [activityName, ssid, newDataCapture])

    useEffect(() => {
        newDataCapture({
            variables: {
                ssid,
                activityName,
                submittedValues: values,
                submit: false,
            }
        }).then(({ data }) => {
            if (JSON.stringify(data.newDataCapture.values) !== JSON.stringify(values)) {
                setValues(data.newDataCapture.values)
            }
        })
    }, [values, activityName, ssid, newDataCapture])

    const onSubmit = async () => {
        const { data, errors } = await newDataCapture({
            variables: {
                ssid,
                activityName,
                submittedValues: values,
                submit: true,
            }
        })
        if (errors || data.newDataCapture.hasSubmitErrors || data.newDataCapture.hasErrors) {
            return;
        }
        history.push(`/patient/${ssid}/summary`);
    }

    // const sdvWarning = (data?.patientActivity?.sdv?.invalidated === false)
    //     ? (
    //         <Alert severity="warning" sx={{ my: '1rem' }}>
    //             <Typography variant="body1">
    //                 This page has been marked as "SDV complete" by {data.patientActivity.sdv.createdBy.title} {data.patientActivity.sdv.createdBy.firstName} {data.patientActivity.sdv.createdBy.lastName}.
    //                 By submitting changes to this page, you will invalidate the SDV status.
    //             </Typography>
    //         </Alert>
    //     ) : (
    //         <></>
    //     )
    const sdvWarning = false;

    return (
        <>
            <ActivityForm
                title={activity?.displayName}
                fields={fields}
                hasSubmitErrors={hasSubmitErrors}
                onSubmit={onSubmit}
                setValues={setValues}
                values={values}
                loading={loading || sdvWarningLoading}
                blockingCauses={blockingCauses}
                isUpdate={isUpdate}
                extraWarnings={[sdvWarning]}
                virtualFieldErrors={virtualFieldErrors}
            />
        </>
    )
}


export const PatientRegistration = () => {
    const history = useHistory()
    const [values, setValues] = useState({})
    const [hasSubmitErrors, setHasSubmitErrors] = useState(false)
    const [selectedIcfVersion, setSelectedIcfVersion] = useState("")

    const [fields, setFields] = useState([])

    const { data, error, loading: icfsLoading } = useQuery(gql`
        query ICFQuery {
            icfs {
                id
                version
            }
        }
    `)

    const [patientRegistration, { loading }] = useMutation(gql`
        mutation PatientRegistrationMutation( $icfVersion: String!, $data: GenericScalar!, $submit: Boolean! ) {
            patientRegistration ( icfVersion: $icfVersion, data: $data, submit: $submit ) {
                hasSubmitErrors

                fields {
                    value
                    errors
                    attribute {
                        id
                        name
                        description
                        displayName
                        dataType
                        minValue
                        maxValue
                        required
                        computed
                        stringEnum {
                            id
                            attributestringenumitemSet {
                                id
                                value
                                presentationOrder
                            }
                        }
                    }
                }

                customFields {
                    value
                    errors
                    attribute
                }

                result {
                    id
                    integrateIdentifier
                    integrateChecksum
                }
            }
        }
    `, {
        onCompleted: data => {
            const { fields, customFields, hasSubmitErrors, result } = data.patientRegistration;
            if (result) {
                history.push(`/patient/${result.integrateIdentifier}/summary?np=true`)
            }
            setFields([...fields, ...customFields])
            setHasSubmitErrors(hasSubmitErrors)
        },
    })

    useEffect(() => {
        if (selectedIcfVersion) {
            patientRegistration({
                variables: {
                    icfVersion: selectedIcfVersion,
                    data: values,
                    submit: false,
                }
            }).then(() => { })
        }
    }, [selectedIcfVersion, values, patientRegistration])

    if (error) return 'error loading ICF forms'
    if (icfsLoading) return <Loading />

    const { icfs } = data

    const onSubmit = async () => {
        await patientRegistration({
            variables: {
                icfVersion: selectedIcfVersion,
                data: values,
                submit: true,
            }
        })
    }

    return (
        <>
            <Container>
                <Card sx={{ mb: 2 }}>
                    <CardContent>
                        <MyTextField
                            sx={{ mb: 0 }}
                            label={"Which ICF version did the patient sign?"}
                            value={selectedIcfVersion}
                            onChange={e => {
                                setSelectedIcfVersion(e.target.value)
                                const key = 'icf_' + e.target.value.replace('.', '_') + '_signed'
                                setValues({ ...values, [key]: true })
                            }}
                            select
                        >
                            <MenuItem value={""}>&nbsp;</MenuItem>
                            {icfs.map(icf => (
                                <MenuItem
                                    key={icf.version}
                                    disabled={icf.version !== '4.0'}
                                    value={icf.version}>{icf.version}</MenuItem>
                            ))}
                        </MyTextField>
                    </CardContent>
                </Card>
            </Container>

            {selectedIcfVersion && (
                <ActivityForm
                    fields={fields}
                    values={values}
                    setValues={setValues}
                    onSubmit={onSubmit}
                    hasSubmitErrors={hasSubmitErrors}
                    loading={loading}
                    blockingCauses={[]}
                    hiddenAttributes={HIDDEN_ATTRIBUTES}
                />
            )}
        </>
    )
}

const LabelValueIfNotEmpty = ({ label, value }) => {
    if (typeof value === 'undefined' || value === null || value === '') return null;
    return (
        <Typography variant="body1">
            {/**
            <span style={{ fontWeight: 'bold' }}>{label}:</span> {value}
            */}
            <span>{label}:</span> {value}
        </Typography>
    )
}

const QUERY_STATE_LABELS = [
    null,
    'Pending',
    'Answered',
    'Closed',
]

const MedicalReviewDetails = () => {
    const { ssid } = useParams()
    const { data, loading, error } = useQuery(gql`
        query MedicalReviewDetailsQuery ( $ssid: String!, $attributes: [String!] ) {
            patient ( ssid: $ssid, attributes: $attributes ) {
                id
                integrateIdentifier
                attrs
                querySet {
                    id
                    originalComment
                    state
                }
            }
        }
    `, {
        variables: {
            ssid,
            attributes: [
                'screening_status',

                'icf_version_signed',

                'icf_1_0_date',
                'icf_1_0_q1', // future contact
                'icf_1_0_q2', // germline
                'icf_1_0_q5', // undefined future research

                'icf_1_1_date',
                'icf_1_1_q1', // future contact
                'icf_1_1_q2', // germline
                'icf_1_1_q5', // undefined future research
                'icf_1_1_q6', // undefined future research if not included

                'icf_2_0_date',
                'icf_2_0_q1', // future contact
                'icf_2_0_q2', // germline
                'icf_2_0_q5', // undefined future research
                'icf_2_0_q6', // undefined future research if not included

                'icf_3_0_date',
                'icf_3_0_q1', // future contact
                'icf_3_0_q2', // undefined future research
                'icf_3_0_q3', // undefined future research if not included
                'icf_3_0_q4', // germline

                'icf_4_0_date',
                'icf_4_0_q1', // future contact
                'icf_4_0_q2', // undefined future research
                'icf_4_0_q3', // undefined future research if not included
                'icf_4_0_q4', // germline

                // ---
                'initial_diagnosis_date',
                'initial_diagnosis_year',
                'primary_tumor_laterality',
                'primary_tumor_treatment_setting',

                'primary_size',
                'primary_histology',
                'primary_histology_other',
                'primary_er_status',
                'primary_pr_status',
                'primary_her2_status',
                'primary_sampling_date',

                'pre_neo_primary_size',
                'pre_neo_primary_histology',
                'pre_neo_primary_histology_other',
                'pre_neo_primary_er_status',
                'pre_neo_primary_pr_status',
                'pre_neo_primary_her2_status',
                'pre_neo_primary_sampling_date',
                
                'post_neo_primary_size',
                'post_neo_primary_histology',
                'post_neo_primary_histology_other',
                'post_neo_primary_er_status',
                'post_neo_primary_pr_status',
                'post_neo_primary_her2_status',
                'post_neo_primary_sampling_date',

                // ---
                'de_novo_metastatic',
                'dob',

                // ---
                'metastatic_site',
                'metastatic_site_other',
                'relapse_type',
                'locoregional_site',
                'diag_biopsy_info_available',
                'diag_biopsy_info_missing_reason',
                'diag_biopsy_histology',
                'diag_biopsy_histology_other',
                'diag_biopsy_er_status',
                'diag_biopsy_pr_status',
                'diag_biopsy_her2_status',
                'diag_biopsy_info_comment',

                // ---
                'late_relapse_cohort_candidate',
                'lobular_cohort_candidate',
                'tnbc_cohort_candidate',

                // ---
                'aurora_biopsy_collection_date',
                'aurora_biopsy_same_lesion_as_diag',
                'aurora_biopsy_info_comment',

                // ---
                'baseline_plasma_collection_date',
            ],
        }
    })
    if (loading) return <Loading />
    if (error) return 'error'
    const { patient } = data
    return (
        <>
            <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} md={8}>
                    <Card>
                        <CardContent>
                            <Stack direction="column">
                                <Typography variant="body1" sx={{ fontWeight: "bold" }}>ICF version {patient.attrs.icf_version_signed}</Typography>

                                {patient.attrs.icf_version_signed === '4.0'
                                ? (
                                    <>
                                        <Typography variant="body1">ICF signature date: {patient.attrs.icf_4_0_date}</Typography>
                                        <Typography variant="body1">A. Future contact: {patient.attrs.icf_4_0_q1}</Typography>
                                        <Typography variant="body1">B. Patient agrees to use of samples &amp; data: {patient.attrs.icf_4_0_q2}</Typography>
                                        <Typography variant="body1">C. Screen Failure - Patient agrees to use of samples &amp; data: {patient.attrs.icf_4_0_q3}</Typography>
                                        <Typography variant="body1">D. Patient wants germline data: {patient.attrs.icf_4_0_q4}</Typography>
                                    </>
                                )
                                : patient.attrs.icf_version_signed === '3.0'
                                ? (
                                    <>
                                        <Typography variant="body1">ICF signature date: {patient.attrs.icf_3_0_date}</Typography>
                                        <Typography variant="body1">A. Future contact: {patient.attrs.icf_3_0_q1}</Typography>
                                        <Typography variant="body1">B. Patient agrees to use of samples &amp; data: {patient.attrs.icf_3_0_q2}</Typography>
                                        <Typography variant="body1">C. Screen Failure - Patient agrees to use of samples &amp; data: {patient.attrs.icf_3_0_q3}</Typography>
                                        <Typography variant="body1">D. Patient wants germline data: {patient.attrs.icf_3_0_q4}</Typography>
                                    </>
                                )
                                : patient.attrs.icf_version_signed === '2.0'
                                ? (
                                    <>
                                        <Typography variant="body1">ICF signature date: {patient.attrs.icf_2_0_date}</Typography>
                                        <Typography variant="body1">A. Future contact: {patient.attrs.icf_2_0_q1}</Typography>
                                        <Typography variant="body1">B. Patient wants germline data: {patient.attrs.icf_2_0_q2}</Typography>
                                        <Typography variant="body1">C. Patient agrees to use of samples &amp; data: {patient.attrs.icf_2_0_q5}</Typography>
                                        <Typography variant="body1">D. Screen Failure - Patient agrees to use of samples &amp; data: {patient.attrs.icf_2_0_q6}</Typography>
                                    </>
                                )
                                : patient.attrs.icf_version_signed === '1.1'
                                ? (
                                    <>
                                        <Typography variant="body1">ICF signature date: {patient.attrs.icf_1_1_date}</Typography>
                                        <Typography variant="body1">A. Future contact: {patient.attrs.icf_1_1_q1}</Typography>
                                        <Typography variant="body1">B. Patient wants germline data: {patient.attrs.icf_1_1_q2}</Typography>
                                        <Typography variant="body1">C. Patient agrees to use of samples &amp; data: {patient.attrs.icf_1_1_q5}</Typography>
                                        <Typography variant="body1">D. Screen Failure - Patient agrees to use of samples &amp; data: {patient.attrs.icf_1_1_q6}</Typography>
                                    </>
                                )
                                : patient.attrs.icf_version_signed === '1.0'
                                ? (
                                    <>
                                        <Typography variant="body1">ICF signature date: {patient.attrs.icf_1_0_date}</Typography>
                                        <Typography variant="body1">A. Future contact: {patient.attrs.icf_1_0_q1}</Typography>
                                        <Typography variant="body1">B. Patient wants germline data: {patient.attrs.icf_1_0_q2}</Typography>
                                        <Typography variant="body1">C. Patient agrees to use of samples &amp; data: {patient.attrs.icf_1_0_q5}</Typography>
                                    </>
                                )
                                : <Typography>MISSING ICF DATA</Typography>
                                }

                                {patient.attrs.icf_version_signed !== '4.0' && (
                                    <Typography variant="body1" color="text.disabled" sx={{ mt: 2 }}>
                                        NOTE: the wording around <strong>undefined future research</strong> changes between ICF versions.
                                    </Typography>
                                )}

                                <Typography variant="body1" sx={{ fontWeight: "bold", mt: 2 }}>Patient registration</Typography>
                                <Typography variant="body1">De Novo: {patient.attrs.de_novo_metastatic}</Typography>
                                <Typography variant="body1">Date of Birth: {patient.attrs.dob}</Typography>
                            </Stack>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card>
                        <CardContent>
                            <Stack direction="column">

                                {patient.attrs.icf_version_signed === '4.0' && (
                                    <Typography variant="body1" sx={{ fontWeight: "bold", mb: 1 }}>AURORA 2.0 Cohort</Typography>
                                )}

                                {patient.attrs.late_relapse_cohort_candidate === 'Yes' && (
                                    <Typography variant="body1">Late relapse</Typography>
                                )}

                                {patient.attrs.lobular_cohort_candidate === 'Yes' && (
                                    <Typography variant="body1">Lobular</Typography>
                                )}

                                {patient.attrs.tnbc_cohort_candidate === 'Yes' && (
                                    <Typography variant="body1">TNBC</Typography>
                                )}

                                {/**
                                <LabelValueIfNotEmpty label="Late relapse" value={patient.attrs.late_relapse_cohort_candidate} />
                                <LabelValueIfNotEmpty label="Lobular" value={patient.attrs.lobular_cohort_candidate} />
                                <LabelValueIfNotEmpty label="TNBC" value={patient.attrs.tnbc_cohort_candidate} />
                                **/}

                                <Typography variant="body1" sx={{ my: 2 }}>
                                    Screening status: <span style={{ fontWeight: 'bold' }}>{patient.attrs.screening_status}</span>
                                </Typography>
                            </Stack>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            {patient.querySet.length === 0
                                ? <Typography variant="body1">This patient has no queries</Typography>
                                : (
                                     <Table>
                                       <TableHead>
                                         <TableRow>
                                           <TableCell>ID</TableCell>
                                           <TableCell>Status</TableCell>
                                           <TableCell>Query</TableCell>
                                         </TableRow>
                                       </TableHead>
                                       <TableBody>
                                        {patient.querySet.map(qs => (
                                          <TableRow>
                                            <TableCell>
                                                <Link href={`/query/${qs.id}`}>
                                                    {qs.id}
                                                </Link>
                                            </TableCell>
                                            <TableCell>{QUERY_STATE_LABELS[qs.state]}</TableCell>
                                            <TableCell>{qs.originalComment}</TableCell>
                                          </TableRow>
                                        ))}
                                       </TableBody>
                                     </Table>
                                  )
                            }
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Card>
                        <CardContent>
                            <Stack direction="column">
                                <Typography variant="body1" sx={{ fontWeight: "bold" }}>Info on primary tumor</Typography>
                                <Typography variant="body1">
                                    Date of initial diagnosis: {patient.attrs.initial_diagnosis_date
                                                                ? patient.attrs.initial_diagnosis_date
                                                                : patient.attrs.initial_diagnosis_year
                                                                ? patient.attrs.initial_diagnosis_year
                                                                : 'n/a'}
                                </Typography>
                                <Typography variant="body1">
                                    Laterality: {patient.attrs.primary_tumor_laterality}
                                </Typography>

                                <Typography variant="body1">Treatment setting: {patient.attrs.primary_tumor_treatment_setting}</Typography>

                                <LabelValueIfNotEmpty label="Tumor size" value={patient.attrs.primary_size} />
                                <LabelValueIfNotEmpty label="Histology" value={patient.attrs.primary_histology} />
                                <LabelValueIfNotEmpty label="ER" value={patient.attrs.primary_er_status} />
                                <LabelValueIfNotEmpty label="PgR" value={patient.attrs.primary_pr_status} />
                                <LabelValueIfNotEmpty label="HER2" value={patient.attrs.primary_her2_status} />
                                <LabelValueIfNotEmpty label="Sampling date" value={patient.attrs.primary_sampling_date} />

                                <LabelValueIfNotEmpty label="Pre-neo tumor size" value={patient.attrs.pre_neo_primary_size} />
                                <LabelValueIfNotEmpty label="Pre-neo histology" value={patient.attrs.pre_neo_primary_histology} />
                                <LabelValueIfNotEmpty label="Pre-neo ER" value={patient.attrs.pre_neo_primary_er_status} />
                                <LabelValueIfNotEmpty label="Pre-neo PgR" value={patient.attrs.pre_neo_primary_pr_status} />
                                <LabelValueIfNotEmpty label="Pre-neo HER2" value={patient.attrs.pre_neo_primary_her2_status} />
                                <LabelValueIfNotEmpty label="Pre-neo sampling date" value={patient.attrs.pre_neo_primary_sampling_date} />

                                <LabelValueIfNotEmpty label="Post-neo tumor size" value={patient.attrs.post_neo_primary_size} />
                                <LabelValueIfNotEmpty label="Post-neo histology" value={patient.attrs.post_neo_primary_histology} />
                                <LabelValueIfNotEmpty label="Post-neo ER" value={patient.attrs.post_neo_primary_er_status} />
                                <LabelValueIfNotEmpty label="Post-neo PgR" value={patient.attrs.post_neo_primary_pr_status} />
                                <LabelValueIfNotEmpty label="Post-neo HER2" value={patient.attrs.post_neo_primary_her2_status} />
                                <LabelValueIfNotEmpty label="Post-neo sampling date" value={patient.attrs.post_neo_primary_sampling_date} />

                            </Stack>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Card>
                        <CardContent>
                            <Stack direction="column">
                                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                                    Info on Metastatic Disease Diagnosis
                                </Typography>
                                <LabelValueIfNotEmpty label="Metastatic site" value={patient.attrs.metastatic_site} />
                                <LabelValueIfNotEmpty label="Metastatic site other" value={patient.attrs.metastatic_site_other} />
                                <LabelValueIfNotEmpty label="Relapse type" value={patient.attrs.relapse_type} />
                                <LabelValueIfNotEmpty label="Locoregional site" value={patient.attrs.locoregional_site} />
                                <LabelValueIfNotEmpty label="Histology" value={patient.attrs.diag_biopsy_histology} />
                                <LabelValueIfNotEmpty label="Histology other" value={patient.attrs.diag_biopsy_histology_other} />
                                <LabelValueIfNotEmpty label="ER" value={patient.attrs.diag_biopsy_er_status} />
                                <LabelValueIfNotEmpty label="PgR" value={patient.attrs.diag_biopsy_pr_status} />
                                <LabelValueIfNotEmpty label="HER2" value={patient.attrs.diag_biopsy_her2_status} />
                                <LabelValueIfNotEmpty label="Comment" value={patient.attrs.diag_biopsy_info_comment} />
                            </Stack>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Card>
                        <CardContent>
                            <Stack direction="column">
                                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                                    AURORA metastatic biopsy
                                </Typography>

                                <LabelValueIfNotEmpty label="AURORA biopsy collection date" value={patient.attrs.aurora_biopsy_collection_date} />
                                <LabelValueIfNotEmpty label="Biopsy collected from metastatic diagnostic biopsy" value={patient.attrs.aurora_biopsy_same_lesion_as_diag} />
                                <LabelValueIfNotEmpty label="Comment" value={patient.attrs.aurora_biopsy_info_comment} />
                                
                                <Typography variant="body1" sx={{ fontWeight: "bold", mt: 2 }}>
                                    Plasma &amp; serum
                                </Typography>
                                <Typography variant="body1">
                                    Plasma collection date: {patient.attrs.baseline_plasma_collection_date}
                                </Typography>
                            </Stack>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            {/**
            <Card sx={{ mt: 2 }}>
                <CardContent>
                    <Typography variant="h5">Attributes</Typography>
                    <Typography variant="body1">Patient {patient.integrateIdentifier}</Typography>
                    <pre>{JSON.stringify(patient.attrs, null, 2)}</pre>
                </CardContent>
            </Card>
            <Card sx={{ mt: 2 }}>
                <CardContent>
                    <Typography variant="h5">Queries</Typography>
                    <ul>
                    {patient.querySet.map(q => (
                        <li>{q.id} | {q.state} | {q.originalComment}</li>
                    ))}
                    </ul>
                </CardContent>
            </Card>
            **/}
        </>
    )
}


const DEBOUNCE_TIMEOUT = 750;


export const ActivityForm = ({ title, fields, values, setValues, onSubmit, hasSubmitErrors, loading, blockingCauses, isUpdate, virtualFieldErrors, hiddenAttributes, extraWarnings }) => {
    hiddenAttributes = hiddenAttributes || [];
    extraWarnings = extraWarnings || [];

    const history = useHistory()

    virtualFieldErrors = virtualFieldErrors || []
    isUpdate = isUpdate || false

    const [showHelpOnFields, setShowHelpOnFields] = useState([])

    const toggleShowHelpOnField = (attributeId) => {
        if (showHelpOnFields.includes(attributeId)) {
            setShowHelpOnFields(showHelpOnFields.filter(id => id !== attributeId))
        } else {
            setShowHelpOnFields([...showHelpOnFields, attributeId])
        }
    }

    // custom deboucer for setting values on free text fields (integers, floats, and strings)):
    const [displayValues, setDisplayValues] = useState({ ...values })
    const [timeouts, setTimeouts] = useState({})
    const debouncedSetValue = (attributeName, newValue) => {
        const timeout = timeouts[attributeName]
        if (timeout) {
            clearTimeout(timeout)
        }
        const newTimeout = setTimeout(() => {
            setValues({ ...values, [attributeName]: newValue })
            setTimeouts({ ...timeouts, [attributeName]: null })
        }, DEBOUNCE_TIMEOUT)
        setDisplayValues({ ...displayValues, [attributeName]: newValue })
        setTimeouts({ ...timeouts, [attributeName]: newTimeout })
    }
    const hasPendingDebounces = Object.values(timeouts).filter(timeout => timeout !== null).length > 0

    useEffect(() => {
        setDisplayValues({ ...values })
    }, [values])

    if (fields.length === 0) <Loading />

    if (blockingCauses.length > 0) {
        return (
            <Container>
                <Card>
                    <CardContent>
                        {blockingCauses.map(cause => <Alert severity="warning">{cause}</Alert>)}
                        <Stack direction="row" spacing={1} mt={2}>
                            <Button variant="outlined" color="secondary" onClick={() => {
                                history.goBack()
                            }}>Cancel</Button>
                        </Stack>
                    </CardContent>
                </Card>
            </Container>
        )
    }

    return (
        <Container>
            {title === 'Medical Review' && (
                <MedicalReviewDetails />
            )}

            <Card>
                <CardContent>
                    {title && (
                        <Typography variant="h5" sx={{ mb: 2 }}>{title}</Typography>
                    )}

                    {extraWarnings.map(warning => warning)}

                    {fields.map(({ attribute, errors }) => {
                        const showError = (errors.length > 0) && hasSubmitErrors
                        const errorText = showError ? errors.join(", ") : '';

                        const helpText = (attribute.description !== attribute.displayName)
                            ? attribute.description
                            : null
                            ;

                        const helperText = showError
                            ? errorText
                            : null
                            ;

                        const disabled = (attribute.name.startsWith('icf_') && attribute.name.endsWith('_signed'));
                        const mb = (helperText !== null) ? 3 : 2;
                        const hidden = hiddenAttributes.indexOf(attribute.name) >= 0 ? { display: 'none' } : {};

                        const helpIconAdornment = helpText ? (
                            <InputAdornment position="start">
                                <IconButton onClick={() => toggleShowHelpOnField(attribute.id)}>
                                    <InfoIcon />
                                </IconButton>
                            </InputAdornment>
                        ) : null;

                        if (attribute.dataType === "Boolean") {
                            return (
                                <>
                                    <MyTextField
                                        key={attribute.id}
                                        sx={{ mb, ...hidden }}
                                        error={showError}
                                        label={attribute.displayName}
                                        disabled={disabled}
                                        value={(values[attribute.name] === undefined || values[attribute.name] === null) ? "" : values[attribute.name].toString()}
                                        onChange={e => {
                                            const newValue = (e.target.value === "true")
                                                ? true
                                                : (e.target.value === "false")
                                                    ? false
                                                    : e.target.value;
                                            setValues({ ...values, [attribute.name]: newValue })
                                        }}
                                        select
                                        helperText={helperText}

                                        InputProps={{
                                            endAdornment: helpIconAdornment,
                                        }}
                                    >
                                        <MenuItem value={""}>&nbsp;</MenuItem>
                                        <MenuItem value={"true"}>Yes</MenuItem>
                                        <MenuItem value={"false"}>No</MenuItem>
                                    </MyTextField>

                                    {showHelpOnFields.includes(attribute.id) && (
                                        <Alert severity="info" sx={{ mb }}>
                                            {helpText}
                                        </Alert>
                                    )}
                                </>
                            )
                        }

                        if (attribute.dataType === "Float" || attribute.dataType === "Integer") {
                            const inputProps = (attribute.minValue !== null && attribute.maxValue !== null)
                                ? { type: "number", min: attribute.minValue, max: attribute.maxValue }
                                : { type: "number" }
                                ;
                            return (
                                <MyTextField
                                    key={attribute.id}
                                    sx={{ mb }}
                                    error={showError}
                                    label={attribute.displayName}
                                    // value={values[attribute.name] || ""}
                                    value={displayValues[attribute.name] || ""}
                                    inputProps={inputProps}
                                    helperText={helperText}
                                    onChange={e => {
                                        debouncedSetValue(attribute.name, e.target.value)
                                        // setValues({ ...values, [attribute.name]: e.target.value });
                                    }}
                                />
                            )
                        }

                        if (attribute.dataType === "Date") {
                            return (
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDatePicker
                                        key={attribute.id}
                                        label={attribute.displayName}
                                        value={values[attribute.name] || null}
                                        onChange={newValue => {
                                            try {
                                                const formattedDate = newValue.format("YYYY-MM-DD")
                                                setValues({ ...values, [attribute.name]: formattedDate });
                                            } catch (e) {
                                                setValues({ ...values, [attribute.name]: null });
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <MyTextField
                                                {...params}
                                                sx={{ mb }}
                                                error={showError}
                                                helperText={helperText}
                                            />
                                        )}
                                        inputFormat="YYYY-MM-DD"
                                    />
                                </LocalizationProvider>
                            )
                        }

                        // if (attribute.dataType === "Date") {
                        //     return <SelectDate
                        //         helperText={helperText}
                        //         error={hasValidationErrors(attribute.name)}
                        //         label={attribute.displayName}
                        //         value={values[attribute.name] || ""}
                        //         onChange={newValue => {
                        //             setValues({ ...values, [attribute.name]: newValue });
                        //             clearValidationErrors(attribute.name);
                        //         }}
                        //     />
                        // }

                        const options = attribute?.stringEnum?.attributestringenumitemSet || []

                        if (options.length > 0) {
                            return (
                                <>
                                    <MyTextField
                                        key={attribute.id}
                                        sx={{ mb }}
                                        error={showError}
                                        label={attribute.displayName}
                                        value={values[attribute.name] || ""}
                                        helperText={helperText}
                                        onChange={e => {
                                            setValues({ ...values, [attribute.name]: e.target.value });
                                        }}
                                        select
                                        InputProps={{
                                            endAdornment: helpIconAdornment,
                                        }}
                                    >
                                        <MenuItem value={""}>&nbsp;</MenuItem>
                                        {options.map(option => (
                                            <MenuItem key={option.id} value={option.value}>{option.displayOption ? option.displayOption : option.value}</MenuItem>
                                        ))}
                                    </MyTextField>

                                    {showHelpOnFields.includes(attribute.id) && (
                                        <Alert severity="info" sx={{ mb: 4 }}>
                                            {helpText}
                                        </Alert>
                                    )}
                                </>
                            )
                        }

                        return (
                            <MyTextField
                                key={attribute.id}
                                sx={{ mb }}
                                error={showError}
                                label={attribute.displayName}
                                // value={values[attribute.name] || ""}
                                value={displayValues[attribute.name] || ""}
                                helperText={helperText}
                                onChange={e => {
                                    // setValues({ ...values, [attribute.name]: e.target.value });
                                    debouncedSetValue(attribute.name, e.target.value)
                                }}
                            />
                        )
                    })}

                    

                    {isUpdate && (
                        <Paper elevation={1} square sx={{ p: 2, mb: 2 }}>
                            <Alert severity="info" sx={{ mb: 2 }}>
                                You are updating a form that has already been submitted. Please provide a reason for this change.
                            </Alert>

                            <MyTextField
                                sx={{ mb: 2 }}
                                label="Change reason"
                                value={values[`change_reason`] || ""}
                                select
                                error={virtualFieldErrors.filter(vfe => vfe.fieldName === 'change_reason').length > 0}
                                onChange={e => {
                                    setValues({ ...values, change_reason: e.target.value });
                                }}
                            >
                                <MenuItem value={""}>&nbsp;</MenuItem>
                                {['New information', 'Data entry error', 'Other'].map(option => (
                                    <MenuItem key={option} value={option}>{option}</MenuItem>
                                ))}
                            </MyTextField>

                            {values?.change_reason === 'Other' && (
                                <MyTextField
                                    sx={{ mb: 2 }}
                                    multiline
                                    error={virtualFieldErrors.filter(vfe => vfe.fieldName === 'change_reason_comment').length > 0}
                                    rows={4}
                                    label="Please describe your your for this change"
                                    value={values[`change_reason_comment`] || ""}
                                    onChange={e => {
                                        setValues({ ...values, change_reason_comment: e.target.value });
                                    }}
                                />
                            )}
                        </Paper>
                    )}

                    {(loading || hasPendingDebounces) ? (
                        <Loading />
                    ) : (
                        <Stack direction="row" spacing={1}>
                            <Button variant="contained" onClick={async () => {
                                await onSubmit();
                            }}>
                                Submit
                            </Button>
                            <Button variant="outlined" color="secondary" onClick={() => {
                                history.goBack()
                            }}>Cancel</Button>
                        </Stack>
                    )}
                </CardContent>
            </Card>
        </Container>
    )
}


export const PatientChecksumVerification = () => {
    const { ssid } = useParams();
    const { me } = useMe();
    const location = useLocation();
    const history = useHistory();

    const [inputChecksum, setInputChecksum] = useState('');
    const { data, loading, error } = useQuery(gql`
        query PatientInfoQuery ( $ssid: String! ) {
            patient ( ssid: $ssid, attributes: [] ) {
                id
                integrateIdentifier
                integrateChecksum
            }
        }
    `, {
        variables: { ssid },
    })

    const [verifiedPatientSsid, setVerifiedPatientSsid] = useLocalStorage('verifiedPatientSsid', '')

    const skipVerification = me?.isMonitor
        || me?.isGroupMonitor
        || me?.isMabMember
        || me?.isPathologist
        || me?.isPathologistPythia
        || me?.isLabMember // is this right?
        ;

    if (loading) return <Loading />
    if (error) return 'error...'

    const { patient } = data
    const { integrateChecksum } = patient

    if (verifiedPatientSsid === ssid || skipVerification) {
        return (
            <>
                <Switch>
                    <Route path="/patient/:ssid/summary"><PatientSummary /></Route>
                    <Route exact path="/patient/:ssid/image_scoring"><>@TODO</></Route>
                    <Route exact path="/patient/:ssid/activity/:activityName"><PatientActivityComponent /></Route>
                </Switch>
            </>
        )
    }

    const query = new URLSearchParams(location.search);
    const newPatient = query.get('np') === 'true'

    if (newPatient) {
        return (
            <Container maxWidth="sm">
                <Paper sx={{ p: 2 }}>
                    <Typography variant="h6">Patient ID {ssid} has the code: <strong>{integrateChecksum.toUpperCase()}</strong></Typography>
                    <Typography sx={{ mb: 2 }} variant="body1">I confirm that I've noted the patient ID and patient code into the patient's record.</Typography>
                    <Button variant="contained" sx={{ mr: 1 }} onClick={() => {
                        setVerifiedPatientSsid(ssid)
                    }}>Continue</Button>
                </Paper>
            </Container>
        )
    }

    console.log(`Patient ${ssid} => ${integrateChecksum.toUpperCase()}`)

    const onSubmit = () => {
        if (inputChecksum.toUpperCase() === integrateChecksum.toUpperCase()) {
            setVerifiedPatientSsid(ssid)
        } else {
            setInputChecksum('')
        }
    }

    return (
        <>
            <Container maxWidth="sm">
                <Paper sx={{ p: 2 }}>
                    <Typography sx={{ mb: 2 }} variant="body1">To continue, please enter the code for patient <strong>{ssid}</strong>:</Typography>
                    <form
                        onSubmit={onSubmit}>
                        <MyTextField
                            label="Patient code"
                            autoFocus
                            value={inputChecksum}
                            onChange={e => {
                                setInputChecksum(e.target.value)
                            }}
                            sx={{ mb: 2 }}
                        />
                        <Button variant="contained" sx={{ mr: 1 }} onClick={onSubmit}>Verify</Button>

                        <Button variant="outlined" color="secondary" onClick={() => {
                            history.goBack()
                        }}>Cancel</Button>
                    </form>
                </Paper>
            </Container>
        </>
    )
}
