import { useQuery, gql, useMutation } from "@apollo/client"
import { Loading, MyTextField } from "./Widgets"
import {
    Stack,
    Container,
    Card,
    CardContent,
    TablePagination,
    Typography,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Divider,
    Button,
    Grid,
    Box,
    Link,
    IconButton,
    Collapse,
    Paper,
    Menu,
    MenuItem,
    Alert,
    Chip,
} from '@mui/material'

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import WarningIcon from '@mui/icons-material/Warning';
import CheckIcon from '@mui/icons-material/Check';
import RemoveIcon from '@mui/icons-material/Remove';

import { useHistory, useLocation, useParams } from "react-router-dom";

import { useState, useContext, useEffect, useReducer, useRef } from "react";
import { useDebounce } from "../Hooks";
import { useMe } from "../providers/Me";
import { SDVCard } from "./SDV";

// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const OPENED = 1
const RESOLVED = 2
const CLOSED = 3

const QUERY_STATE_LABELS = [
    null,
    'Pending',
    'Answered',
    'Closed',
]

const QUERY_STATE_CHIP_COLORS = [
    null,
    'primary',
    'success',
    'default',
]

export const QueryDetails = () => {
    const { me } = useMe();
    const { id } = useParams()
    const history = useHistory()
    const { data, loading, error, refetch } = useQuery(gql`
        query QueryDetails ( $id: ID! ) {
            query ( id: $id ) {
                id
                state
                patient {
                    id
                    integrateIdentifier
                }
                createdBy {
                    id
                    title
                    lastName
                    organization {
                        id
                        breastCode
                        shortName
                    }
                }
                patientActivities {
                    id
                    activity {
                        id
                        name
                        displayName
                    }
                }
                patientAttributes {
                    id
                    attribute {
                        id
                        name
                        displayName
                        activity {
                            id
                            name
                            displayName
                        }
                    }
                    value
                }
                comments {
                    id
                    text
                    createdAt
                    createdBy {
                        id
                        title
                        firstName
                        lastName
                        organization {
                            id
                            breastCode
                            shortName
                            fullName
                        }
                    }
                }
            }
        }
    `, {
        variables: { id },
    })

    const [comment, setComment] = useState('')

    const [reopenQuery] = useMutation(gql`
        mutation ReopenQueryMutation ( $id: ID!, $comment: String! ) {
            reopenQuery ( id: $id, comment: $comment ) {
                id
                state
            }
        }
    `)

    const [closeQuery] = useMutation(gql`
        mutation CloseQueryMutation ( $id: ID!, $comment: String ) {
            closeQuery ( id: $id, comment: $comment ) {
                id
                state
            }
        }
    `)

    const [resolveQuery] = useMutation(gql`
        mutation ResolveQueryMutation ( $id: ID!, $comment: String! ) {
            resolveQuery ( id: $id, comment: $comment ) {
                id
                state
            }
        }
    `)

    if (loading) return <Loading />
    if (error) return 'error'

    const { query } = data
    const { patient, comments, patientActivities, patientAttributes } = query

    const commentsLength = comments.length

    const isAnyMonitor = (me.isMonitor || me.isGroupMonitor)
    const showResolveQuery = ((me.isInvestigator || me.isNurse) && query.state === OPENED)
    const showReopenQuery = (isAnyMonitor && (query.state === RESOLVED))
    const showCloseQuery = (isAnyMonitor && (query.state === OPENED || query.state === RESOLVED))

    const submit = func => () => {
        func({ variables: { id: query.id, comment } })
            .then(() => {
                refetch()
                setComment('')
            });
    }

    const activityOnClick = (patient, activityName) => () => {
        if (me?.isMonitor || me?.isGroupMonitor) {
            history.push(`/patient/${patient.integrateIdentifier}/summary/graph/?activity=${activityName}`)
        } else if (me?.isInvestigator || me?.isNurse) {
            history.push(`/patient/${patient.integrateIdentifier}/activity/${activityName}`)
        }
    }

    return (
        <>
            <Container>
                <Card sx={{ mb: 2 }}>
                    <CardContent>
                        <Typography variant="h6">
                            Patient {patient.integrateIdentifier}
                            <Box component="span" sx={{ color: 'text.disabled' }}> | Query {query.id.padStart(4, '0')}</Box>
                        </Typography>
                        <Chip
                            label={QUERY_STATE_LABELS[query.state]}
                            color={QUERY_STATE_CHIP_COLORS[query.state]}
                        />
                    </CardContent>
                </Card>

                <Card>
                    <CardContent>
                        {comments.map((comment, commentIndex) => (
                            <div key={commentIndex}
                                style={{
                                    display: 'block',
                                    width: '100%',
                                    // marginBottom: '2rem',
                                    minHeight: '5rem',
                                }}>
                                <Stack direction="row">
                                    <div style={{ minWidth: '15rem', maxWidth: '15rem' }}>
                                        <Typography variant="body2" color="text.secondary">{comment.createdAt}</Typography>
                                        <Typography variant="body1">{comment.createdBy.title} {comment.createdBy.lastName}</Typography>

                                        {comment.createdBy.organization.breastCode
                                            ? <>
                                                <Typography variant="body2" noWrap>{comment.createdBy.organization.fullName}</Typography>
                                                <Typography variant="body2" color="text.secondary">{comment.createdBy.organization.breastCode}</Typography>
                                            </>
                                            : <>
                                                <Typography variant="body2">{comment.createdBy.organization.fullName}</Typography>
                                            </>
                                        }
                                    </div>

                                    <div>
                                        <Typography variant="body1">{comment.text}</Typography>
                                        {commentIndex === 0 && (
                                            <Stack direction="row" spacing={4} py={2}>
                                                {(patientActivities.length > 0) && (
                                                    <Box sx={{ border: '1px solid', p: 1 }}>
                                                        <Typography variant="body1">Concerned page{patientActivities.length > 1 && <>s</>}:</Typography>
                                                        <Stack direction="column">

                                                            {patientActivities.map(pa => (
                                                                <Link
                                                                    key={pa.id}
                                                                    variant="body1" underline="none" sx={{ color: "primary", cursor: 'pointer' }}
                                                                    // onClick={() => history.push(`/patient/${patient.integrateIdentifier}/activity/${pa.activity.name}`)}
                                                                    onClick={activityOnClick(patient, pa.activity.name)}
                                                                >
                                                                    {pa.activity.displayName}
                                                                </Link>
                                                            ))}

                                                        </Stack>
                                                    </Box>
                                                )}

                                                {(patientAttributes.length > 0) && (
                                                    <Box sx={{ border: '1px solid', p: 1 }}>
                                                        <Typography variant="body1">Concerned field{patientAttributes.length > 1 && <>s</>}:</Typography>
                                                        <Stack direction="column">
                                                            {patientAttributes.map(av => (
                                                                <Link key={av.id} variant="body1" underline="none" sx={{ color: "primary", cursor: 'pointer' }}
                                                                    // onClick={() => history.push(`/patient/${patient.integrateIdentifier}/activity/${av.attribute.activity.name}`)}
                                                                    onClick={activityOnClick(patient, av.attribute.activity.name)}
                                                                >
                                                                    {av.attribute.displayName}
                                                                </Link>
                                                            ))}
                                                        </Stack>
                                                    </Box>
                                                )}
                                            </Stack>
                                        )}
                                    </div>
                                </Stack>

                                {commentIndex !== (commentsLength - 1) && (
                                    <Divider sx={{ my: 2 }} />
                                )}
                            </div>
                        ))}
                    </CardContent>
                </Card>

                {(showResolveQuery || showReopenQuery || showCloseQuery) && (
                    <Card sx={{ mt: 2 }}>
                        <CardContent>
                            <Box>
                                <MyTextField
                                    label="Add a comment to this thread"
                                    multiline
                                    autoFocus
                                    rows={3}
                                    value={comment}
                                    onChange={e => setComment(e.target.value)}
                                />

                                <Stack sx={{ mt: 1 }} spacing={1} direction="row">
                                    {showResolveQuery && <Button variant="outlined" color="primary" onClick={submit(resolveQuery)}>Resolve Query</Button>}
                                    {showReopenQuery && <Button variant="outlined" color="primary" onClick={submit(reopenQuery)}>Reopen Query</Button>}
                                    {showCloseQuery && <Button variant="outlined" color="primary" onClick={submit(closeQuery)}>Close Query</Button>}
                                </Stack>
                            </Box>
                        </CardContent>
                    </Card>
                )}
            </Container>
        </>
    )
}


export const PatientQueryListing = ({ ssid }) => {
    const history = useHistory();
    const [searchTerm, setSearchTerm] = useState("")
    const debouncedSearchTerm = useDebounce(searchTerm, 350)
    const [searchArgs, setSearchArgs] = useState({
        q: debouncedSearchTerm,
        skip: 0,
        first: 10,
    })
    const PENDING = 1
    const ANSWERED = 2
    const CLOSED = 3
    const [queryStateFilter, setQueryStateFilter] = useState([PENDING, ANSWERED, CLOSED])
    const toggleQueryStateFilter = state => {
        if (queryStateFilter.indexOf(state) === -1) {
            setQueryStateFilter([...queryStateFilter, state])
        } else {
            setQueryStateFilter(queryStateFilter.filter(s => s !== state))
        }
    }
    const { data, loading, error } = useQuery(gql`
        query QueryListing ( $ssid: String!, $searchArgs: SearchQueryInput!, $stateFilter: [Int!]! ) {
            patient (ssid: $ssid, attributes: []) {
                id
                integrateIdentifier
                site {
                    id
                    breastCode
                }
            }
            queries ( ssid: $ssid, searchArgs: $searchArgs, stateFilter: $stateFilter ) {
                page
                count
                results {
                    id
                    originalComment
                    createdBy {
                        id
                        firstName
                        lastName
                        title
                    }
                    createdAt
                }
            }
        }
    `, {
        variables: {
            ssid,
            searchArgs,
            stateFilter: queryStateFilter,
        },
        fetchPolicy: 'no-cache'
    })
    useEffect(() => {
        setSearchArgs({ ...searchArgs, q: debouncedSearchTerm, skip: 0 })
    }, [debouncedSearchTerm])
    if (error) return 'error'
    const patient = data?.patient
    const queries = data?.queries
    const results = data?.queries?.results

    if (patient && queries && queries.count === 0) return ''

    return (
        <Card sx={{ mt: 2 }}>
            <CardContent>
                <Stack direction="column" spacing={2}>
                    <MyTextField
                        label={`Search by query ID ...`}
                        // label={`Search by query ID, patient ID, or site code`}
                        value={searchTerm}
                        onChange={e => {
                            setSearchTerm(e.target.value)
                        }}
                        autoFocus
                    />
                    <FormGroup row sx={{ mb: 10 }}>
                        <FormControlLabel control={<Checkbox onChange={() => toggleQueryStateFilter(PENDING)} color="primary" checked={queryStateFilter.indexOf(PENDING) > -1} />} labelPlacement="end" label="Pending" />
                        <FormControlLabel control={<Checkbox onChange={() => toggleQueryStateFilter(ANSWERED)} color="primary" checked={queryStateFilter.indexOf(ANSWERED) > -1} />} labelPlacement="end" label="Answered" />
                        <FormControlLabel control={<Checkbox onChange={() => toggleQueryStateFilter(CLOSED)} color="primary" checked={queryStateFilter.indexOf(CLOSED) > -1} />} labelPlacement="end" label="Closed" />
                    </FormGroup>
                    {loading
                        ? (<Loading />)
                        : (
                            <>
                                {results.length === 0
                                    ? (<Typography variant="body1">No results found.</Typography>)
                                    : (
                                        <>
                                            <Table size="small">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>ID</TableCell>
                                                        <TableCell>Text</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {results.map(r => (
                                                        <TableRow key={r.id} hover sx={{ cursor: 'pointer' }} onClick={() => {
                                                            history.push(`/query/${r.id}`)
                                                        }}>
                                                            <TableCell>{r.id}</TableCell>
                                                            <TableCell>
                                                                <Stack direction="column">
                                                                    {r.originalComment}
                                                                    <Typography color="text.secondary" variant="body2">Created by {r.createdBy.title} {r.createdBy.lastName} on {r.createdAt}</Typography>
                                                                </Stack>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                            <TablePagination
                                                component="div"
                                                count={queries.count}
                                                page={queries.page}
                                                rowsPerPage={searchArgs.first}
                                                onPageChange={(e, newPage) => {
                                                    setSearchArgs({ ...searchArgs, skip: newPage * searchArgs.first })
                                                }}
                                                onRowsPerPageChange={e => {
                                                    setSearchArgs({ ...searchArgs, first: e.target.value })
                                                }}
                                            />
                                        </>
                                    )}
                            </>
                        )
                    }
                </Stack>
            </CardContent>
        </Card>
    )
}


export const QueryListing = () => {
    // const { me } = useMe()
    const history = useHistory();
    const [searchTerm, setSearchTerm] = useState("")
    const debouncedSearchTerm = useDebounce(searchTerm, 350)
    const [searchArgs, setSearchArgs] = useState({
        q: debouncedSearchTerm,
        skip: 0,
        first: 10,
    })

    const PENDING = 1
    const ANSWERED = 2
    const CLOSED = 3

    const [queryStateFilter, setQueryStateFilter] = useState([PENDING, ANSWERED])

    const toggleQueryStateFilter = state => {
        if (queryStateFilter.indexOf(state) === -1) {
            setQueryStateFilter([...queryStateFilter, state])
        } else {
            setQueryStateFilter(queryStateFilter.filter(s => s !== state))
        }
    }

    const { data, loading, error } = useQuery(gql`
        query QueryListing ( $searchArgs: SearchQueryInput!, $stateFilter: [Int!]! ) {
            queries ( searchArgs: $searchArgs, stateFilter: $stateFilter ) {
                page
                count
                results {
                    id
                    originalComment
                    patient {
                        id
                        integrateIdentifier
                        site {
                            id
                            breastCode
                        }
                    }
                    createdBy {
                        id
                        firstName
                        lastName
                        title
                    }
                    createdAt
                }
            }
        }
    `, {
        variables: {
            searchArgs,
            stateFilter: queryStateFilter,
        },
        fetchPolicy: 'no-cache'
    })

    useEffect(() => {
        setSearchArgs({ ...searchArgs, q: debouncedSearchTerm, skip: 0 })
    }, [debouncedSearchTerm])

    if (error) return 'error'
    const queries = data?.queries
    const results = data?.queries?.results
    return (
        <Container>
            <Card>
                <CardContent>
                    <Stack direction="column" spacing={2}>
                        <MyTextField
                            label={`Search by query ID, patient ID, site code`}
                            value={searchTerm}
                            onChange={e => {
                                setSearchTerm(e.target.value)
                            }}
                            autoFocus
                        />

                        <FormGroup row sx={{ mb: 10 }}>
                            <FormControlLabel control={<Checkbox onChange={() => toggleQueryStateFilter(PENDING)} color="primary" checked={queryStateFilter.indexOf(PENDING) > -1} />} labelPlacement="end" label="Pending" />
                            <FormControlLabel control={<Checkbox onChange={() => toggleQueryStateFilter(ANSWERED)} color="primary" checked={queryStateFilter.indexOf(ANSWERED) > -1} />} labelPlacement="end" label="Answered" />
                            <FormControlLabel control={<Checkbox onChange={() => toggleQueryStateFilter(CLOSED)} color="primary" checked={queryStateFilter.indexOf(CLOSED) > -1} />} labelPlacement="end" label="Closed" />
                        </FormGroup>

                        {loading
                            ? (<Loading />)
                            : (
                                <>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>ID</TableCell>
                                                <TableCell>Patient</TableCell>
                                                <TableCell>Site</TableCell>
                                                <TableCell>Text</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {results.map(r => (
                                                <TableRow key={r.id} hover sx={{ cursor: 'pointer' }} onClick={() => {
                                                    // @TODO!!!
                                                    history.push(`/query/${r.id}`)
                                                }}>
                                                    <TableCell>{r.id}</TableCell>
                                                    <TableCell>{r.patient.integrateIdentifier}</TableCell>
                                                    <TableCell>{r.patient.site.breastCode}</TableCell>
                                                    <TableCell>
                                                        <Stack direction="column">
                                                            {r.originalComment}
                                                            <Typography color="text.secondary" variant="body2">Created by {r.createdBy.title} {r.createdBy.lastName} on {r.createdAt}</Typography>
                                                        </Stack>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                    <TablePagination
                                        component="div"
                                        count={queries.count}
                                        page={queries.page}
                                        rowsPerPage={searchArgs.first}
                                        onPageChange={(e, newPage) => {
                                            setSearchArgs({ ...searchArgs, skip: newPage * searchArgs.first })
                                        }}
                                        onRowsPerPageChange={e => {
                                            setSearchArgs({ ...searchArgs, first: e.target.value })
                                        }}
                                    />
                                </>
                            )
                        }
                    </Stack>
                </CardContent>
            </Card>
        </Container>
    )
}


const formatRawValue = v => {
    if (v === null || v === undefined || v === '') return <Box component="span" sx={{ color: 'text.disabled' }}>blank</Box>
    if (typeof v === 'boolean') return v ? 'Yes' : 'No';
    return String(v)
}

const CollapsableRow = ({ row, state, dispatch }) => {
    // const [open, setOpen] = useState(false)

    const open = state.patientActivityIds.includes(row.id)
    const ref = useRef();

    useEffect(() => {
        if (open) {
            ref.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            })
        }
    }, [open])

    const hasRequiredSdvFields = row.dataTableAttributeValues.some(av => av.attribute.sdvRequirement < 2)
    const hasRequiredSdvFieldsThatAreNotCompleted = row.dataTableAttributeValues.some(av => av.attribute.sdvRequirement < 2 && !av.sdv?.id)
    const hasAllFieldsSdvCompleted = row.dataTableAttributeValues.every(av => av.sdv?.id)
    const hasCompletedAllRequiredFields = row.dataTableAttributeValues.every(av => av.attribute.sdvRequirement === 0 && av.sdv?.id)

    const numberOfFields = row.dataTableAttributeValues.length
    const numberOfSdvCompletedFields = row.dataTableAttributeValues.filter(av => av.sdv?.id).length
    // const numberOfCriticalFieldsNotCompleted = row.dataTableAttributeValues.filter(av => av.attribute.sdvRequirement === 0 && !av.sdv?.id).length


    const text = `${numberOfSdvCompletedFields} / ${numberOfFields}`
    const color = hasRequiredSdvFieldsThatAreNotCompleted
        ? 'error'
        : hasAllFieldsSdvCompleted
        ? 'success'
        : 'warning';

    const completionStatus = <Chip color={color} label={`${numberOfSdvCompletedFields} / ${numberOfFields}`} />

    return (
        <>
            <TableRow onClick={() => dispatch({ type: 'toggle_patient_activity', id: row.id })} ref={ref}>
                <TableCell padding="checkbox">
                    <IconButton size="small">
                        {open ? <KeyboardArrowDownIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    {row.activity.displayName}
                </TableCell>
                <TableCell align="right">
                    {row.state}
                </TableCell>
                <TableCell align="right">{completionStatus}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={4} style={{ paddingBottom: 0, paddingTop: 0 }}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ m: 2, border: 1, borderRadius: 1, p: 2 }}>
                            <>
                                {row.dataTableAttributeValues.length === 0
                                    ? (
                                        <></>
                                    ) : (
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell></TableCell>
                                                    <TableCell>Attribute</TableCell>
                                                    <TableCell>Value</TableCell>
                                                    <TableCell align="right">SDV</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {row.dataTableAttributeValues.map(av => (
                                                    <TableRow key={av.id} onClick={() => dispatch({ type: 'toggle_attribute_value', id: av.id })}
                                                        sx={av.attribute.sdvRequirement === 0 && !av.sdv?.id ? {
                                                            color: 'error',
                                                        } : {}}
                                                    >
                                                        <TableCell padding="checkbox">
                                                            <Checkbox checked={state.attributeValueIds.includes(av.id)} />
                                                        </TableCell>
                                                        <TableCell style={av.attribute.sdvRequirement === 0 ? { fontWeight: 'bold' } : {}}>{av.attribute.displayName}</TableCell>
                                                        <TableCell style={av.attribute.sdvRequirement === 0 ? { fontWeight: 'bold' } : {}}>{formatRawValue(av.value)}</TableCell>
                                                        <TableCell align="right">
                                                            {((av.attribute.sdvRequirement === 0) && (!av.sdv?.id)) && (<WarningIcon color="error" />)}
                                                            {((av.attribute.sdvRequirement === 0) && (av.sdv?.id)) && (<CheckIcon color="success" />)}
                                                            {(av.attribute.sdvRequirement === 2) && <RemoveIcon color="disabled" />}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    )}

                                {(row.sdv?.invalidated === false) && (
                                    <SDVCard sdv={row.sdv} />
                                )}
                            </>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

const queryReducer = (state, action) => {
    switch (action.type) {
        case 'toggle_attribute_value':
            return {
                ...state,
                attributeValueIds: state.attributeValueIds.includes(action.id)
                    ? state.attributeValueIds.filter(id => id !== action.id)
                    : [...state.attributeValueIds, action.id]
            }
        case 'toggle_patient_activity':
            return {
                ...state,
                patientActivityIds: state.patientActivityIds.includes(action.id)
                    ? state.patientActivityIds.filter(id => id !== action.id)
                    : [...state.patientActivityIds, action.id]
            }
        case 'set_comment':
            return {
                ...state,
                comment: action.comment
            }
        case 'set_screen':
            return {
                ...state,
                screen: action.screen
            }
        case 'reset':
            return {
                ...state,
                attributeValueIds: [],
                patientActivityIds: [],
                comment: '',
                screen: 'listing',
            }
        default:
            throw new Error();
    }
}

export const CreatePatientQuery = ({ ssid }) => {
    const { me } = useMe();
    const history = useHistory()

    const location = useLocation()
    const queryParameters = new URLSearchParams(location.search)

    const [state, dispatch] = useReducer(queryReducer, {
        ssid,
        attributeValueIds: [],
        patientActivityIds: [],
        comment: '',
        screen: 'listing',
    })

    const [checkedSearchParams, setCheckedSearchParams] = useState(false)

    const { data, loading, error, refetch } = useQuery(gql`
        query PatientAttributeValues ($ssid: ID!) {
            patientTables (ssid: $ssid) {
                id
                state
                activity {
                    id
                    name
                    displayName
                    siteActivity
                }
                dataTableAttributeValues {
                    id
                    attribute {
                        id
                        name
                        displayName
                        sdvRequirement
                        computed
                    }
                    value
                    sdv {
                        id
                    }
                }
            }
        }
    `, { variables: { ssid } })

    const [createQuery, { error: createQueryError }] = useMutation(gql`
        mutation CreatePatientQueryMutation ( $ssid: ID!, $attributeValueIds: [ID!]!, $patientActivityIds: [ID!]!, $comment: String! ) {
            createQuery ( ssid: $ssid, attributeValueIds: $attributeValueIds, patientActivityIds: $patientActivityIds, comment: $comment ) {
                id
                state
            }
        }
    `)

    const [createSdv, { error: createSDVError }] = useMutation(gql`
        mutation CreateSdvMutation ( $ssid: ID!, $attributeValueIds: [ID!]!, $comment: String! ) {
            createSdv ( input: { ssid: $ssid, attributeValueIds: $attributeValueIds, comment: $comment } ) {
                id
            }
        }
    `)

    const [includeNonSiteActivities, setIncludeNonSiteActivities] = useState(false)

    const hasCommentText = state.comment.length >= 2;

    const onSubmit = () => {
        createQuery({ variables: { ...state } })
            .then(({ data }) => {
                const { id } = data.createQuery
                history.push(`/query/${id}`)
            })
    }

    const onSubmitSdv = () => {
        createSdv({ variables: { ...state } })
            .then(({ data }) => {
                // const { id } = data.createSdv
                // history.push(`/sdv/${id}`)
                dispatch({ type: 'reset' })
                refetch()
            })
    }

    if (loading) return <Loading />
    if (error) return 'error...'

    if (!checkedSearchParams && queryParameters.get('activity')) {
        setCheckedSearchParams(true);
        const activityName = queryParameters.get('activity')
        const activity = data.patientTables.find(row => row.activity.name === activityName)
        if (activity) {
            dispatch({ type: 'toggle_patient_activity', id: activity.id })
        }
    }

    const completionStatusIcon = (color, numberOfSdvCompletedFields, numberOfFields) => <Chip color={color} label={`${numberOfSdvCompletedFields} / ${numberOfFields}`} />

    if (state.screen === 'listing') {
        return (
            <Stack direction="column" spacing={2} sx={{ my: 2 }}>
                {me?.isOperator && (
                    <Paper sx={{ p: 2 }}>
                        <FormControlLabel control={<Checkbox
                            checked={includeNonSiteActivities}
                            onChange={e => setIncludeNonSiteActivities(e.target.checked)}
                            color="primary" />}
                            label="Include non-site activities"
                        />
                    </Paper>
                )}

                <Paper>
                    <Box sx={{ p: 2, mb: 4 }}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Icon</TableCell>
                                    <TableCell>SDV requirements / status</TableCell>
                                    <TableCell>Description</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>{completionStatusIcon('warning', 0, 1)}</TableCell>
                                    <TableCell><Typography variant="">Not required</Typography></TableCell>
                                    <TableCell><Typography variant="">SDV is not required for the form, but you can still perform SDV if desired.</Typography></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{completionStatusIcon('error', 1, 5)}</TableCell>
                                    <TableCell><Typography variant="">Required</Typography></TableCell>
                                    <TableCell><Typography variant="">Some fields on the form must be verified.</Typography></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{completionStatusIcon('success', 4, 4)}</TableCell>
                                    <TableCell><Typography variant="">Completed</Typography></TableCell>
                                    <TableCell><Typography variant="">SDV has been completed.</Typography></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><WarningIcon color="error" /></TableCell>
                                    <TableCell><Typography variant="">Required</Typography></TableCell>
                                    <TableCell><Typography variant="">Every field with this icon must be verified.</Typography></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><CheckIcon color="success" /></TableCell>
                                    <TableCell><Typography variant="">Completed</Typography></TableCell>
                                    <TableCell><Typography variant="">Every field with this icon has been verified.</Typography></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Box>

                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Activity</TableCell>
                                <TableCell align="right">State</TableCell>
                                <TableCell align="right">SDV</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.patientTables
                                .filter((row) => includeNonSiteActivities ? true : (row.activity.siteActivity === true))
                                .map((row, i) => <CollapsableRow key={i} row={row} state={state} dispatch={dispatch} />)}
                        </TableBody>
                    </Table>

                    <Box sx={{ p: 2 }}>
                        <PositionedMenu dispatch={dispatch} state={state} />
                    </Box>
                </Paper>
            </Stack >
        )
    }

    if (state.screen === 'query') {
        return (
            <Stack direction="column" spacing={2} sx={{ my: 2 }}>
                <SelectedAttributesTable data={data} state={state} dispatch={dispatch} />
                <Paper sx={{ p: 2 }}>
                    <form onSubmit={onSubmit}>
                        <MyTextField
                            label="Query description (required)"
                            multiline
                            rows={5}
                            fullWidth
                            value={state.comment}
                            onChange={e => dispatch({ type: 'set_comment', comment: e.target.value })}
                            sx={{ mb: 2 }}
                        />
                        {createQueryError && <Alert severity="error">Error submitting form.</Alert>}
                        <Button
                            disabled={!hasCommentText}
                            variant="contained"
                            color="primary"
                            onClick={onSubmit}
                        >Submit Query</Button>
                    </form>
                </Paper>
            </Stack>
        )
    }

    if (state.screen === 'sdv') {
        return (
            <Stack direction="column" spacing={2} sx={{ my: 2 }}>
                <SelectedAttributesTable data={data} state={state} dispatch={dispatch} />
                <Paper sx={{ p: 2 }}>
                    <MyTextField
                        label="Additional comments (optional)"
                        multiline
                        rows={5}
                        fullWidth
                        value={state.comment}
                        onChange={e => dispatch({ type: 'set_comment', comment: e.target.value })}
                        sx={{ mb: 2 }}
                    />
                    <Button
                        disabled={false}
                        variant="contained"
                        color="primary"
                        onClick={onSubmitSdv}
                    >Confirm source document verification</Button>
                </Paper>
            </Stack>
        )
    }
}

const SelectedAttributesTable = ({ data, state, dispatch }) => (
    <Paper sx={{ p: 2 }}>
        <Button
            variant="outlined"
            color="primary"
            onClick={() => dispatch({ type: 'set_screen', screen: 'listing' })}
            startIcon={<ChevronLeftIcon />}
        >Go Back</Button>
        <Table size="small" sx={{ my: 4 }}>
            <TableHead>
                <TableRow>
                    <TableCell>Selected attribute(s):</TableCell>
                    <TableCell>Value</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {state.attributeValueIds.map((avId) => {
                    const av = data.patientTables.flatMap(row => row.dataTableAttributeValues).find(av => av.id === avId)
                    return (
                        <TableRow key={av.id}>
                            <TableCell>{av.attribute.displayName}</TableCell>
                            <TableCell>{formatRawValue(av.value)}</TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    </Paper>
)

const PositionedMenu = ({ dispatch, state }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div>
            <Button
                id="demo-positioned-button"
                aria-controls={open ? 'demo-positioned-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                variant="contained"
                color="primary"
                disabled={state.attributeValueIds.length === 0}
            >
                Actions
            </Button>

            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={() => { handleClose(); dispatch({ type: 'set_screen', screen: 'query' }) }}>Create a new query</MenuItem>
                <MenuItem onClick={() => { handleClose(); dispatch({ type: 'set_screen', screen: 'sdv' }) }}>Mark as Source Document Verified</MenuItem>
            </Menu>
        </div>
    )
}
