import * as d3 from 'd3'
import * as d3Tip from "d3-tip"

import { Settings } from '../Settings'
import { useRef, useEffect, useReducer, useState, useCallback } from 'react';

import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

// import ReactDOMServer from 'react-dom/server';

import _ from 'lodash';

import {
    Container,
    Card,
    CardContent,
    CardHeader,

    Button,
    Stack,

    Grid,

    FormControl,
    Select,
    InputLabel,
    MenuItem,
    ListItemText,
    Checkbox,
    OutlinedInput,

    Typography,
    Box,
    Alert,
} from '@mui/material'
import { gql, useQuery } from '@apollo/client';
import { Loading, MyTextField } from './Widgets';
import { useTheme } from '@mui/material/styles';
import { dispatch } from 'd3';


const DashboardGraph = ({ elementRef, title, loading, maxHeight, headerComponent, dispatch }) => {

    const scrollStyleProps = maxHeight ? { maxHeight } : {};

    const onResetFilters = () => {
        dispatch({type: 'resetFilters'});
    }

    return (
        <Card sx={{ mb: 2 }}>
            <CardHeader title={title} action={
                <Button color="primary" onClick={onResetFilters}>
                    <FilterAltOffIcon />
                    Remove Filters
                </Button>
            } />
            <CardContent
                sx={{
                    overflow: 'auto',
                    '&::-webkit-scrollbar': {
                      width: 9,
                    },
                    '&::-webkit-scrollbar-track': {
                      // boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
                      background: 'transparent',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      // backgroundColor: '#888',
                      backgroundColor: 'rgba(155, 155, 155, 0.5)',
                      outline: '1px solid slategrey',
                      borderRadius: '20px',
                      border: 'transparent',
                    },
                    scrollbarWidth: 'thin',
                }}
                >

                {headerComponent}

                {loading && <Loading />}
                <div ref={elementRef}
                    style={{ width: '100%', ...scrollStyleProps }}
                    >
                    <svg
                        style={{
                            width: '100%',
                            minHeight: 100,
                            margin: 0,
                            padding: 0,
                        }}
                        ></svg>
                </div>
            </CardContent>
        </Card>
    )
}


const getWidthOfElement = (element) => {
    if (!element) return 1;
    const style = window.getComputedStyle(element);
    const width = element.offsetWidth;
    const padding = parseFloat(style.paddingLeft) + parseFloat(style.paddingRight);
    const border = parseFloat(style.borderLeftWidth) + parseFloat(style.borderRightWidth);
    const result = width - padding - border;
    return result;
}

const renderChart2 = (el, data, theme) => {
    const svg = el.select('svg');
    const valueLabelWidth = 80; // space reserved for value labels (right)
    const barHeight = theme.typography.fontSize; // height of one bar
    const barLabelWidth = 260; // space reserved for bar labels
    const barLabelPadding = 5; // padding between bar and bar labels (left)
    const gridLabelHeight = 18; // space reserved for gridline labels
    const gridChartOffset = 3; // space between start of grid and first bar
    const maxBarWidth = Math.floor(getWidthOfElement(el.node()) - valueLabelWidth - barLabelWidth - barLabelPadding - gridChartOffset);
    const barLabel = (d) => d.label;
    const barValue = (d) => parseInt(d.value)
    const yHeight = ( data.length * barHeight );
    const yScale = d3.scaleBand()
        .domain(data.map(barLabel))
        .range([0, yHeight])
    const y = (d, i) => yScale(d.label);
    const yText = (d, i) => y(d, i) + yScale.bandwidth() / 2;
    const m = d3.max(data, barValue) || 100;
    const x = m < 8
        ? d3.scaleLinear().domain([0, 10]).range([0, maxBarWidth])
        : d3.scaleLinear().domain([0, m+1]).range([0, maxBarWidth]);

    const updateLabelsWidth = (svg, gridContainer, verticleLineLabelSelection, verticleLineSelection, labelsContainer, barsContainer, barsSelection, barLabelsSelection) => {
        const barLabelWidth = svg.select('g.labels-container').node() ? svg.select('g.labels-container').node().getBBox().width + 10 : 250;
        const maxBarWidth = Math.floor(getWidthOfElement(el.node()) - valueLabelWidth - barLabelWidth - barLabelPadding - gridChartOffset);
        const x = m < 8
            ? d3.scaleLinear().domain([0, 10]).range([0, maxBarWidth])
            : d3.scaleLinear().domain([0, m+1]).range([0, maxBarWidth]);
        svg.transition().duration(500)
            .attr('width', maxBarWidth + barLabelWidth + valueLabelWidth)
        gridContainer.transition().duration(500)
            .attr('transform', `translate(${barLabelWidth}, ${gridLabelHeight})`)
        labelsContainer.transition().duration(500)
            .attr('transform', 'translate(' + (barLabelWidth - barLabelPadding) + ',' + (gridLabelHeight + gridChartOffset) + ')')
        barsContainer.transition().duration(500)
            .attr('transform', 'translate(' + barLabelWidth + ',' + (gridLabelHeight + gridChartOffset) + ')');
        verticleLineLabelSelection.transition().duration(500)
            // .data(x.ticks(15), (d, i) => d)
            .attr('x', x)
        verticleLineSelection.transition().duration(500)
            .attr('x1', x)
            .attr('x2', x)
            .attr("y1", 0)
            .attr("y2", (data.length * barHeight) + gridChartOffset)
        barsSelection.transition().duration(500)
            .attr('width', d => x(barValue(d)))
            .attr('fill', () => theme.palette.primary.main)
        barLabelsSelection.transition().duration(1500)
            .attr('fill', theme.palette.text.primary)
            .attr('x', d => x(barValue(d)))
            .attr('y', yText)
            .text(d => d.value)
    }

    svg
        .attr('width', maxBarWidth + barLabelWidth + valueLabelWidth)
        .attr('height',gridLabelHeight + gridChartOffset + data.length * barHeight );

    // grid line labels
    const gridContainer = svg.select('g.grid-container').node()
        ? svg.select('g.grid-container')
        : svg.append('g')
            .attr('class', 'grid-container')
            .attr('transform', `translate(${barLabelWidth}, ${gridLabelHeight})`);

    const verticleLineLabelSelection = gridContainer.selectAll("text.grid-line-label")
        .data(x.ticks(15), (d, i) => d);
    
    verticleLineLabelSelection
        .transition()
            .attr('fill', theme.palette.text.disabled)
            .attr('x', x)
            .text(String)

    verticleLineLabelSelection
        .exit().remove();

    verticleLineLabelSelection
        .enter()
            .append("text")
            .attr("class", 'grid-line-label')
            .attr("x", x)
            .attr("dy", -3)
            .attr("text-anchor", "middle")
            .text(String)

    // vertical grid lines
    const verticleLineSelection = gridContainer.selectAll("line.verticle-grid-line")
        .data(x.ticks(15), (d, i) => d)

    verticleLineSelection
        .transition()
            .attr('stroke', theme.palette.text.disabled)
            .attr('x1', x)
            .attr('x2', x)
            .attr("y2", (data.length * barHeight) + gridChartOffset)

    verticleLineSelection
        .exit().remove();

    verticleLineSelection
        .enter().append("line")
            .attr('class', 'verticle-grid-line')
            .attr("x1", x)
            .attr("x2", x)
            .attr("y1", 0)
            .attr("y2", (data.length * barHeight) + gridChartOffset)

    // bar labels
    const labelsContainer = svg.select('g.labels-container').node()
        ? svg.select('g.labels-container')
        : svg.append('g')
            .attr('class', 'labels-container')
            .attr('transform', 'translate(' + (barLabelWidth - barLabelPadding) + ',' + (gridLabelHeight + gridChartOffset) + ')');

    const labelsSelection = labelsContainer.selectAll('text.label')
        .data(data, (d, i) => i)

    labelsSelection
        .transition()
            .attr('fill', theme.palette.text.primary)
            .attr('y', yText)
            .text(barLabel)

    labelsSelection
        .exit().remove();

    labelsSelection
        .enter()
            .append('text')
            .attr('class', 'label')
            .attr('y', yText)
            .attr('stroke', 'none')
            .attr("dy", ".35em")
            .attr('text-anchor', 'end')
            .text(barLabel)

    // const labelsWidth = labelsContainer.node().getBBox().width;

    const barsContainer = svg.select('g.bars-container').node()
        ? svg.select('g.bars-container')
        : svg.append('g')
            .attr('class', 'bars-container')
            .attr('transform', 'translate(' + barLabelWidth + ',' + (gridLabelHeight + gridChartOffset) + ')');

    const barsSelection = barsContainer.selectAll("rect.bar")
        .data(data, (d, i) => i)


    const onMouseover = e => {
        const { target } = e
        d3.select(target).style("cursor", "pointer");
        d3.select(target)
            .transition()
            .duration(200)
            // .attr('stroke', () => )
            .attr('fill', () => theme.palette.primary.dark)
    }

    const onMouseout = e => {
        const { target } = e
        d3.select(target).style("cursor", "default");
        d3.select(target)
            .transition()
            .duration(500)
            .attr('fill', () => theme.palette.primary.main)
            .attr('stroke', () => theme.mode === 'dark' ? 'white' : 'black');
    }

    barsSelection
        .transition().duration(1000)
            .attr('stroke', theme.mode === 'dark' ? 'white' : 'black')
            // .attr('stroke-width', 1)
            .attr('fill', theme.palette.primary.main)
            .attr('y', y)
            .attr('height', yScale.bandwidth())
            .attr('width', d => x(barValue(d)))

    barsSelection
        .exit().remove()

    barsSelection
        .enter()
            .append("rect")
            .attr('class', 'bar')
            .attr('y', y)
            .attr('fill', () => theme.palette.primary.main)
            .attr('height', yScale.bandwidth())
            .attr('width', d => x(barValue(d)))
            // .on('mouseover', onMouseover)
            // .on('mouseout', onMouseout)
    
    barsSelection
        .on('mouseover', onMouseover)
        .on('mouseout', onMouseout)

    // bar value labels
    const barLabelsSelection = barsContainer.selectAll("text.bar-label")
        .data(data, (d, i) => i)

    barLabelsSelection
        .transition()
            .attr('fill', theme.palette.text.primary)
            .attr('x', d => x(barValue(d)))
            .attr('y', yText)
            .text(d => d.value)

    barLabelsSelection
        .exit().remove();

    barLabelsSelection
        .enter()
            .append("text")
            .attr("class", "bar-label")
            .attr("x", d => x(barValue(d)))
            .attr("y", yText)
            .attr("dx", 10) // padding-left
            .attr("dy", ".35em") // vertical-align: middle
            .attr("text-anchor", "start") // text-align: right
            .attr("stroke", "none")
            .text((d) => d.value)

    const startLine = barsContainer.select('line.start-line').node()
        ? barsContainer.select('line.start-line')
        : barsContainer.append("line")
            .attr("class", "start-line")
            .attr("y1", -gridChartOffset)
            .attr("y2", (data.length * barHeight) + gridChartOffset)

    startLine
        .transition()
            .style("stroke", theme.palette.text.primary)
            .attr("y1", -gridChartOffset)
            .attr("y2", (data.length * barHeight) + gridChartOffset)

    updateLabelsWidth(svg, gridContainer, verticleLineLabelSelection, verticleLineSelection, labelsContainer, barsContainer, barsSelection, barLabelsSelection)
}


const PATIENT_ACTIVITY_STATUS_COLORS = {
    'Succeeded': 'rgb(51,160,44)', // dark green
    'Activated': 'rgb(31,120,180)', // "
    'To do (urgent)': 'rgb(251,154,153)', // soft pink
    'Pending': 'rgb(178,223,138)', // light green
    // 'Planned': 'grey', // light blue
    // 'Assigned': 'rgb(166,206,227)',
    'Planned': 'rgb(166,206,227)',
    'Cancelled': 'black',
}


const DEFAULT_ACTIVITY_NAMES = [
    'icf_1_0',
    'icf_1_1',
    'icf_2_0',
    'icf_3_0',
    'icf_4_0',
    
    'patient_registration',
    'known_germline_info',
    'diag_biopsy_info',

    'primary_info',
    'kit_registration',
    'aurora_biopsy_info',
    'blood_collection_info',
    'plasma_serum_collection_info',
    'shipment_request',
    'sample_reception',
    'report_issue_at_site',
    'report_issue_at_lab',

    'report_auto',
    'report_mab',
]

const ALL_ACTIVITY_NAMES = [
    'icf_1_0',
    'icf_1_1',
    'icf_2_0',
    'icf_3_0',
    'icf_4_0',

    'primary_info',
    'kit_registration',
    'aurora_biopsy_info',

    'patient_registration',
    'known_germline_info',
    'diag_biopsy_info',
    'primary_info',
    'kit_registration',
    'aurora_biopsy_info',
    'aurora_biopsy_2_info',
    'blood_collection_info',
    'plasma_serum_collection_info',

    'shipment_request',
    'sample_reception',
    
    'pathology',
    'primary_seq',
    'metastatic_seq',
    'blood_seq',
    'primary_cnv',
    'metastatic_cnv',
    'compare_seq',
    'ctdna_seq',
    'ctdna_cnv',

    'check_pathology',
    'primary_seq_results',
    'primary_drugs',
    'meta_seq_results',
    'meta_drugs',
    'blood_seq_results',
    'blood_drugs',
    'pathology_file',
    'ctdna_seq_results',
    'ctdna_drugs',

    'report_auto',
    'report_mab',
    'image_scoring',

    // 'progression_biopsy_info',
    // 'progression_biopsy_shipment_request',
    // 'progression_biopsy_reception',
    // 'progression_biopsy_seq',
    // 'progression_biopsy_cnv',
    // 'progression_biopsy_report_auto',
    // 'progression_biopsy_report_mab',

    'report_issue_at_site',
    'report_issue_at_lab',
    // 'progression_report_issue_at_site',
    // 'progression_report_issue_at_lab',
]

const ICF_VERSIONS = [
    '1.0',
    '1.1',
    '2.0',
    '3.0',
    '4.0',
]

const useD3 = (renderChartFn, dependencies) => {
    const ref = useRef()
    useEffect(() => {
        renderChartFn(d3.select(ref.current));
    }, dependencies)
    return ref
}


const PatientsPerSiteChart = ({state, dispatch}) => {
    const theme = useTheme()
    const { data, loading, error } = useQuery(gql`
        query PatientsPerSiteQuery ( $filters: DashboardFiltersInputType ) {
            dashboard ( filters: $filters ) {
                sites {
                    id
                    breastCode
                    patientSet {
                        id
                        integrateIdentifier
                    }
                }
            }
        }
    `, {
        variables: {
            filters: { ...state.form }
        }
    })

    const formatBarCharData = sites => sites
        .map(s => ({
            label: s.breastCode,
            value: s.patientSet.length,
        }))
        .filter(d => d.value > 0)
        .sort((a, b) => b.value - a.value)

    const ref = useD3((svg) => {
        return renderChart2(svg, formatBarCharData(data?.dashboard?.sites || []), theme)
    }, [data, theme])

    useResizeObserver(ref, () => {
        renderChart2(d3.select(ref.current), formatBarCharData(data?.dashboard?.sites || []), theme)
    })

    if (error) return 'error...'

    return (
        <DashboardGraph elementRef={ref} title="PATIENTS BY SITE" loading={loading} maxHeight={300} dispatch={dispatch} />
    )
}

const tip = d3Tip.default()
    .attr('class', 'd3-tip')
    .html((d) => `
        ${d.activity}<br />
        Patient ${d.patient.integrateIdentifier}<br />${d.status}<br />Since ${d.date}
    `);

const margin = {
    // top: 250,
    top: 120,
    right: 120,
    bottom: 50,
    left: 120,
};

// const rw = 26
// const rh = 12;

const renderMatrix = (el, data, theme) => {
    // const rows = data?.patientActivityMatrixData?.rows || [];
    // const activityNames = data?.patientActivityMatrixData?.activityNames || [];
    const { rows, activityNames } = data

    // don't allow negative values to pass:
    const matrixWidth = Math.max(getWidthOfElement(el.node()) - margin.left - margin.right, 1);
    const rw = matrixWidth / activityNames.length;
    const rh = theme.typography.fontSize;
    // const rh = 12;

    const height = ( ( rh + 2 ) * rows.length ) + margin.top + margin.bottom;
    // const svg = d3.select(ref.current)
    //     .append("svg")
    //     .call(tip);
    const svg = el.select('svg');
    svg.call(tip);

    svg.attr('width', '100%');
    svg.attr('height', height);

    const svgColumnHeadersSelection = svg.selectAll('g.column-header')
        .data(activityNames)

    svgColumnHeadersSelection
        .transition()
          .attr('transform', (d, i) => {
              return 'translate(' + ( margin.left + 10 + ((2+rw) * i) ) + ',' + (margin.top - 10)  + ')';
          })

    svgColumnHeadersSelection
        .exit().remove()

    svgColumnHeadersSelection.selectAll('text')
        .transition()
            .style('fill', theme.palette.text.primary)
            .text((d) => d);

    svgColumnHeadersSelection
        .enter()
          .append('g')
            .attr('class', 'column-header')
            .attr('x', 0)
            .attr('y', -10)
            // .style('outline', '1px solid red')
            .attr('transform', (d, i) => {
                return 'translate(' + ( margin.left + 10 + ((2+rw) * i) ) + ',' + (margin.top - 10)  + ')';
            })
          .append('text')
            .attr('class', 'column-header-text')
            .attr('transform', 'rotate(-45)')
            .style('fill', theme.palette.text.primary)
            .text((d) => d);

    const svgRowsSelection = svg.selectAll('g.row')
        .data(rows, (d) => d.id)

    svgRowsSelection
        .transition()
            .attr('transform', function(d, i) {
                return 'translate(' + margin.left + ',' + ( margin.top + ((2+rh) * i) ) + ')';
            })

    svgRowsSelection
        .exit().remove()

    svgRowsSelection.selectAll('text.row-label')
        .transition()
            .style('fill', theme.palette.text.primary)

    svgRowsSelection
        .enter()
          .append('g')
            .attr('class', 'row')
            // .style('outline', '1px solid yellow')
            .attr('transform', function(d, i) {
                return 'translate(' + margin.left + ',' + (margin.top+((2+rh)*i)) + ')';
            })
          .append('text')
            .attr('class', 'row-label')
            .attr('x', -margin.left)
            .attr('y', 12)
            // .style('font-size', '12px')
            .style('fill', theme.palette.text.primary)
            .html(d => `${d.patient.integrateIdentifier}-${d.patient.site.breastCode}`)
            .on('mouseover', function(){
                d3.select(this).style("cursor", "pointer");
            })
            .on('mouseout', function(){
                d3.select(this).style("cursor", "default");
            })
            .on('click', function(pointer, d){
                const ssid = d.patient.integrateIdentifier;
                // history.push(`/patient/${ssid}/summary`)
                window.open(`/patient/${ssid}/summary`, '_blank')
            });



    const activityRectSelection = svg.selectAll('g.row').selectAll('rect.activity-rect')
        .data((d, i) => {
            const r = d.patientActivities
            return r
        })

    activityRectSelection
        .transition()
            .attr('width', rw)
            .attr('height', rh)
            .attr('x', (d, i) => ( rw + 2 ) * i)
            .style('fill', d => {
                const { state } = d || {};
                const emptyColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)';
                return PATIENT_ACTIVITY_STATUS_COLORS[state] || emptyColor;
            })

    activityRectSelection
        .exit().remove()

    activityRectSelection
        .enter()
            .append('rect')
            .attr('class', 'activity-rect')
            .attr('width', rw)
            .attr('height', rh)
            .attr('stroke-width', 2)
            .attr('x', (d, i) => ( rw + 2 ) * i)
            .style('fill', (d, i) => {
                const { state } = d || {};
                const emptyColor = theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)';
                return PATIENT_ACTIVITY_STATUS_COLORS[state] || emptyColor;
            })

    svg.selectAll('rect.activity-rect')
        .on('mouseover', (e, d) => {
            const { target } = e;
            if (!d) return;
            d3.select(target)
                .attr('stroke-width', 4)
                .attr('stroke', theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.8)' : d3.select(target).style('fill'))
                .attr('stroke-opacity', 0.4)
                ;
            const row = d3.select(target.parentNode).datum();
            const { patient } = row;
            tip.show({
                patient,
                activity: d.activity.name,
                status: d.state,
                date: d.since,
            }, d3.select(target).node())
        })
        .on("mouseout", (e, d) => {
            if (!d) return;
            d3.select(e.target).attr('stroke-width', 0);
            tip.hide();
        });
}

const AnotherMatrix = ({ state, dispatch }) => {
    const PAGE_SIZE = 50;

    const theme = useTheme()
    // const loadMoreRef = useRef(null)
    const [skip, setSkip] = useState(0)
    const [allRows, setAllRows] = useState([])
    const [hasMore, setHasMore] = useState(true)

    const { data, loading, error, refetch } = useQuery(gql`
        query PatientActivityMatrixDataQuery ( $icfVersions: [String!], $activityNames: [String!], $skip: Int! ) {
            patientActivityMatrixData ( icfVersions: $icfVersions, activityNames: $activityNames, skip: $skip, first: ${PAGE_SIZE} ) {
                activityNames
                rows {
                    patient {
                        id
                        integrateIdentifier
                        site {
                            id
                            breastCode
                        }
                    }
                    patientActivities {
                        id
                        state
                        since
                        activity {
                            id
                            name
                        }
                    }
                }
            }
        }
    `, {
        variables: { ...state.form, skip },
        onCompleted: ({ patientActivityMatrixData }) => {
            const { rows } = patientActivityMatrixData;
            if (rows.length < PAGE_SIZE) {
                setHasMore(false)
            }
            const l = [...allRows, ...rows]
            setAllRows(l)
        },
    })

    useEffect(() => {
        setAllRows([])
        setHasMore(true)
        setSkip(0)
    }, [ state.form ])

    const ref = useD3((svg) => {
        const d = {
            rows: allRows,
            activityNames: state.form.activityNames,
        }
        renderMatrix(svg, d, theme)
    }, [data, theme])

    useResizeObserver(ref, () => {
        const d = {
            rows: allRows,
            activityNames: state.form.activityNames,
        }
        renderMatrix(d3.select(ref.current), d, theme)
    })

    if (error) return 'error...'

    const disabled = loading || !hasMore;

    return (
        <>
            <DashboardGraph elementRef={ref} title="ACTIVITIES MATRIX" loading={loading} headerComponent={
                <ActivitiesChoiceInput state={state} dispatch={dispatch} />
            }/>
            <Button
                onClick={async () => {
                    const newSkip = skip + PAGE_SIZE;
                    setSkip(newSkip)
                    const { data } = await refetch({
                        ...state.form,
                        skip: newSkip,
                    })
                    const { patientActivityMatrixData } = data;
                    const { rows } = patientActivityMatrixData;
                }}
                disabled={disabled}
                >Load more</Button>
                {/**
                <Typography ref={loadMoreRef}>
                    {hasMore ? 'loading ...' : ''}
                </Typography>
                */}
        </>
    )
}


const ScreeningFailureReasonsChart = ({ state, dispatch }) => {
    const theme = useTheme()
    const { data, loading, error } = useQuery(gql`
        query ScreeningFailureReasonsQuery ( $filters: DashboardFiltersInputType ) {
            dashboard ( filters: $filters ) {
                screeningFailures {
                    reason
                    count
                }
            }
        }
    `, {
        variables: {
            filters: { ...state.form }
        }
    })
    const formatBarCharData = reasons => reasons
            .map(r => ({
                label: r.reason,
                value: r.count,
            }))
    const ref = useD3(svg => {
        if (!(svg && data)) return;
        return renderChart2(svg, formatBarCharData(data?.dashboard?.screeningFailures || []), theme)
    })
    useResizeObserver(ref, () => {
        if (!(ref && ref.current && data)) return;
        return renderChart2(d3.select(ref.current), formatBarCharData(data?.dashboard?.screeningFailures || []), theme)
    })
    return (
        <>
            <DashboardGraph elementRef={ref} title="SCREENING FAILURE REASONS" loading={loading} headerComponent={
                <Alert severity="warning" sx={{ mb: 4 }}>
                    This source of this data changed with icf 3. These numbers do not include icf 3 or 4 patients!
                </Alert>
            } />
        </>
    )
}


const dashboardReducer = (state, action) => {
    switch (action.type) {
        case 'setIcfVersions':
            return {
                ...state,
                form: {
                    ...state.form,
                    icfVersions: action.value,
                }
            }
        case 'setActivityNames':
            return {
                ...state,
                form: {
                    ...state.form,
                    activityNames: action.value,
                }
            }
        case 'setScreeningStatus':
            return {
                ...state,
                form: {
                    ...state.form,
                    screeningStatus: action.value,
                }
            }
        case 'resetFilters':
            return {
                ...state,
                form: {
                    ...state.form,
                    screeningStatus: [...SCREENING_STATUS],
                    icfVersions: [...ICF_VERSIONS],
                }
            }
        default:
            return state
    }
}

const ActivitiesChoiceInput = ({ state, dispatch }) => (
    <FormControl sx={{ mb: 2, width: '100%' }}>
        <InputLabel id="activities-label">Activities</InputLabel>
        <Select
            labelId="activities-label"
            id="activities-id"
            value={state.form.activityNames || []}
            input={<OutlinedInput label="User account roles" />}
            renderValue={(selected) => {
                const s = ALL_ACTIVITY_NAMES
                    .filter(name => selected.indexOf(name) > -1)
                    .join(', ')
                    ;
                if (s.length > 40) {
                    return s.substring(0, 40) + '...';
                }
                return s
            }}
            onChange={e => {
                dispatch({ type: 'setActivityNames', value: e.target.value })
            }}
            multiple
            >
                {ALL_ACTIVITY_NAMES.map(name => (
                    <MenuItem key={name} value={name}>
                        <Checkbox checked={state.form.activityNames.indexOf(name) > -1} />
                        <ListItemText primary={name} />
                    </MenuItem>
                ))}
            </Select>
    </FormControl>
)

export const SCREENING_STATUS = [
    'Included',
    'Failed',
    'Pending',
]

const FiltersForm = ({ state, dispatch }) => {
    return (
        <Stack direction="row" spacing={2}>
            <FormControl sx={{ mb: 2, width: '100%' }}>
                <InputLabel id="icf-versions-label">ICF versions</InputLabel>
                <Select
                    labelId="icf-versions-label"
                    id="icf-versions-id"
                    value={state.form.icfVersions || []}
                    input={<OutlinedInput label="ICF Versions"/>}
                    renderValue={(selected) => {
                    return ICF_VERSIONS
                        .filter(i => selected.indexOf(i) > -1)
                        .join(', ')
                        ;
                }}
                onChange={e => {
                    dispatch({ type: 'setIcfVersions', value: e.target.value })
                }}
                multiple
                >
                    {ICF_VERSIONS.map(i => (
                        <MenuItem key={i} value={i}>
                            <Checkbox checked={state.form.icfVersions.indexOf(i) > -1} />
                            <ListItemText primary={i} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl sx={{ mb: 2, width: '100%' }}>
                <InputLabel id="screening-staus-label">Screening status</InputLabel>
                <Select
                    labelId="screening-staus-label"
                    id="screening-status-id"
                    value={state.form.screeningStatus || []}
                    input={<OutlinedInput label="Screening status"/>}
                    renderValue={(selected) => {
                    return SCREENING_STATUS
                        .filter(i => selected.indexOf(i) > -1)
                        .join(', ')
                        ;
                }}
                onChange={e => {
                    dispatch({ type: 'setScreeningStatus', value: e.target.value })
                }}
                multiple
                >
                    {SCREENING_STATUS.map(i => (
                        <MenuItem key={i} value={i}>
                            <Checkbox checked={state.form.screeningStatus.indexOf(i) > -1} />
                            <ListItemText primary={i} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Stack>
    )
}


const PatientsPerInvestigatorChart = ({ state, dispatch }) => {
    const theme = useTheme();
    const { data, loading, error } = useQuery(gql`
        query PatientsPerInvestigatorQuery ( $filters: DashboardFiltersInputType ) {
            dashboard ( filters: $filters ) {
                investigators {
                    id
                    username
                    investigatorPatientSet {
                        id
                        integrateIdentifier
                    }
                }
            }
        }`, { variables: { filters: { ...state.form } }
    })
    const formatBarCharData = investigators => investigators
            .map(i => ({
                label: i.username,
                value: i.investigatorPatientSet.length,
            }))
            .filter(d => d.value > 0)
            .sort((a, b) => b.value - a.value)
    const ref = useD3(svg => {
        return renderChart2(
            svg,
            formatBarCharData(data?.dashboard?.investigators || []),
            theme,
        );
    }, [data, theme])
    useResizeObserver(ref, () => {
        renderChart2(d3.select(ref.current), formatBarCharData(data?.dashboard?.investigators || []), theme);
    })
    return (
        <DashboardGraph elementRef={ref} title="PATIENTS BY INVESTIGATOR" loading={loading} maxHeight={300} />
    )
}

const PatientResultCount = ({state, dispatch}) => {
    const { data, loading, error } = useQuery(gql`
        query PatientResultCountQuery ( $filters: DashboardFiltersInputType ) {
            dashboard ( filters: $filters ) {
                patients {
                    id
                    integrateIdentifier
                }
            }
        }`, { variables: { filters: { ...state.form } } }
    )
    if (loading) return <Loading />
    if (error) return 'error...'
    return (
        <Typography variant="h4" component="div" sx={{ fontWeight: 'bold' }}>
            Showing {data.dashboard.patients.length} patients
        </Typography>
    )
}

const useResizeObserver = (ref, callback) => {
    useEffect(() => {
        const ro = new ResizeObserver(callback);
        ro.observe(ref.current);
        return () => ro.disconnect();
    }, [ref, callback])
}

// const daysBetween = (date1, date2) => {
//     const oneDay = 24 * 60 * 60 * 1000;
//     return Math.round(Math.abs((new Date(date1).getTime() - new Date(date2).getTime()) / oneDay));
// }

const renderPatientTimeline = (el, groupedSites, theme) => {

    const PATIENT_INCLUSION_STATUS_COLORS = {
        "Included": theme.palette.primary.main,
        "Included MS2 Pending": theme.palette.primary.main,
        "Included MS2 No": theme.palette.primary.main,
        "Included MS2 Yes": theme.palette.primary.main,
        "Failed": 'pink',
        "Pending": theme.palette.warning.main,
    }


    const svg = el.select('svg')
    const numRows = groupedSites.reduce((acc, g) => acc + g.sites.length, 0)
    const config = {
        rowHeight: theme.typography.fontSize,
        lhsColWidth: 200,
        topAxis: {
            height: 0,
            yPadding: 10,
        },
        circleOpacity: 0.8,
    }

    const maxBarWidth = Math.floor(getWidthOfElement(el.node()) - config.lhsColWidth);

    const sites = groupedSites.map(g => g.sites).flat(1)
    const patients = sites.map(s => s.patients).flat(1)
    const oldestRegistrationDate = patients.reduce((acc, p) => p.registrationDate < acc ? p.registrationDate : acc, '9999-99-99')
    const latestRegistrationDate = patients.reduce((acc, p) => p.registrationDate > acc ? p.registrationDate : acc, '0000-00-00')
    const timeScale = d3.scaleTime()
        .domain([new Date(oldestRegistrationDate), new Date(latestRegistrationDate)])
        .range([0, maxBarWidth])
    const xAxis = d3.axisTop(timeScale)
        .ticks(30)
        .tickFormat(d3.timeFormat('%Y-%m-%d'));
    const xAxisGroup = svg.select('g.x.axis').node()
        ? svg.select('g.x.axis')
        : svg.append('g').attr('class', 'x axis')
            .call(xAxis)
            // .selectAll('text')
            //   .style('text-anchor', 'start')
            //   .attr('transform', 'rotate(-45)')

    xAxisGroup
        .style('font-size', theme.typography.fontSize)
        .style('font-family', theme.typography.fontFamily)

    xAxisGroup
        .selectAll('text')
          .style('text-anchor', 'start')
          .attr('transform', 'rotate(-45)')

    config.topAxis.height = xAxisGroup.node().getBBox().height
    
    xAxisGroup
        .transition()
        .duration(2000)
        .call(xAxis)
        .attr('transform', () => {
            return `translate(${config.lhsColWidth}, ${config.topAxis.height})`
        })

    svg.attr('height', numRows * config.rowHeight + (config.topAxis.height + config.topAxis.yPadding))
        .attr('width', '100%')

    const groupHeights = groupedSites.map(g => g.sites.length * (config.rowHeight))
    const groupOffset = i => {
        const r = groupHeights
            .slice(0, i)
            .reduce((acc, h) => acc + h, config.topAxis.height + config.topAxis.yPadding + config.rowHeight);
        return r;
    }

    const updateSite = function(d, i) {
        const backgroundRect = d3.select(this).select('rect.site-background').node()
            ? d3.select(this).select('rect.site-background')
            : d3.select(this).append('rect')
                .attr('class', 'site-background')
                .attr('x', 0)
                .attr('y', -config.rowHeight)
                .attr('width', '100%')
                .attr('height', config.rowHeight)
                .attr('fill', theme.palette.mode === 'dark' ? 'white' : 'black')
                .attr('fill-opacity', 0)
                .on('mouseover', e => {
                    const { target } = e
                    d3.select(target).attr('fill-opacity', 0.1)
                })
                .on('mouseout', e => {
                    const { target } = e
                    d3.select(target).attr('fill-opacity', 0)
                })

        backgroundRect.transition().duration(500)
            .attr('fill', theme.palette.mode === 'dark' ? 'white' : 'black')

        const circleSelection = d3.select(this).selectAll('circle.patient-marker')
            .data(d.patients, (patient, i) => patient.integrateIdentifier)
        circleSelection.exit().remove()
        circleSelection.transition().duration(500)
            .attr('cx', 0)
            .style('fill-opacity', config.circleOpacity)
            .attr('transform', d => {
                const x = timeScale(new Date(d.registrationDate)) + config.lhsColWidth
                return `translate(${x}, 0)`
            })
            .attr('fill', (d) => PATIENT_INCLUSION_STATUS_COLORS[d.attrs.screening_status])
        circleSelection.enter()
            .append('circle')
                .attr('class', 'patient-marker')
                .attr('pointer-events', 'all')
                .attr('cx', 0)
                .attr('cy', -(config.rowHeight / 2))
                .attr('r', config.rowHeight / 2.1)
                .attr('fill', (d) => PATIENT_INCLUSION_STATUS_COLORS[d.attrs.screening_status])
                .style('fill-opacity', config.circleOpacity)
                .attr('transform', d => {
                    const x = timeScale(new Date(d.registrationDate)) + config.lhsColWidth
                    return `translate(${x}, 0)`
                })
                .on("click", (pointer, d) => {
                    // console.log("you clicked on ", d)
                })
                .on("mouseover", (e, d) => {
                    const parent = d3.select(e.target).node().parentNode
                    const selectParent = d3.select(parent)
                    selectParent.select('rect.site-background').attr('fill-opacity', 0.1)
                    d3.select(e.target)
                        .attr('r', (config.rowHeight / 2.1) + 4)
                        .attr('fill-opacity', config.circleOpacity - 0.2)
                        .attr('stroke', 'black')
                })
                .on("mouseout", (e, d) => {
                    const parent = d3.select(e.target).node().parentNode
                    const selectParent = d3.select(parent)
                    selectParent.select('rect.site-background').attr('fill-opacity', 0)
                    d3.select(e.target)
                        .attr('r', config.rowHeight / 2.1)
                        .attr('stroke', 'none')
                        .attr('fill-opacity', config.circleOpacity)
                })
    }

    const updateGroup = function(d, i) {
        const siteSelection = d3.select(this).selectAll('g.site-row')
            .data(d.sites, (site, i) => site.breastCode)
        siteSelection.exit().remove()
        siteSelection.transition().duration(500)
            .attr('transform', (d, i) => `translate(0, ${i * config.rowHeight})`)
            .each(updateSite)
        siteSelection.selectAll('text')
            .html(d => d.breastCode)
            .attr('fill', theme.palette.text.primary)
        siteSelection.enter()
            .append('g')
                .attr('class', 'site-row')
                .attr('width', '100%')
                .attr('height', config.rowHeight)
                // .style('fill', 'white')
                // .style('fill-opacity', 0)
                .attr('transform', (d, i) => {
                    return `translate(0, ${i * config.rowHeight})`
                })
                .each(updateSite)
            .append('text')
                .html(d => d.breastCode)
                .attr('fill', theme.palette.text.primary)
    }

    const siteGroups = svg.selectAll('g.site-group')
        .data(groupedSites, (d, i) => d.groupName)
    siteGroups.exit().remove()
    siteGroups.transition().duration(500)
        .attr('height', d => d.sites.length * config.rowHeight)
        .attr('transform', (d, i) => {
            return `translate(0, ${groupOffset(i)})`
        })
        .each(updateGroup)

    siteGroups.enter()
      .append('g')
        .attr('class', 'site-group')
        .attr('height', d => d.sites.length * config.rowHeight)
        .attr('transform', (d, i) => {
            return `translate(0, ${groupOffset(i)})`
        })
        .each(updateGroup)
}

const MONITORING_GROUP = 'monitoring_group'
const COUNTRY = 'country'
const NONE = 'none'
const FIRST_PATIENT = 'first_patient'
const LAST_PATIENT = 'last_patient'
const NUM_PATIENTS = 'num_patients'

const PatientTimeline = ({ state, dispatch }) => {
    const theme = useTheme();
    const [groupBy, setGroupBy] = useState(NONE)
    const [sortBy, setSortBy] = useState(FIRST_PATIENT)
    const { data, loading, error } = useQuery(gql`
        query PatientTimelineQuery ( $filters: DashboardFiltersInputType ) {
            dashboard ( filters: $filters ) {
                patients {
                    id
                    attrs
                    integrateIdentifier
                    registrationDate
                    site {
                        id
                        breastCode
                    }
                }
            }
        }
    `, { variables: { filters: { ...state.form } } })

    const formatData = patients => {
        const breastCodes = [...new Set(patients.map(p => p.site.breastCode))] // get unique codes
        const sites = breastCodes
            .map(breastCode => ({
                breastCode,
                patients: patients
                    .filter(p => p.site.breastCode === breastCode)
                    .sort((a, b) => (a.registrationDate < b.registrationDate ? -1 : 1))
            }))
            .sort((a, b) => sortBy === FIRST_PATIENT 
                ? (a.patients[0].registrationDate < b.patients[0].registrationDate ? -1 : 1)
                : (sortBy === LAST_PATIENT)
                ? (a.patients[a.patients.length - 1].registrationDate > b.patients[b.patients.length - 1].registrationDate ? -1 : 1)
                : (sortBy === NUM_PATIENTS) ? a.patients.length > b.patients.length ? -1 : 1
                : 0
            );
        if (groupBy === NONE || groupBy === MONITORING_GROUP) return [
            { groupName: 'None', sites },
        ];
        if (groupBy === COUNTRY) {
            const countries = [...new Set(sites.filter(s => s.breastCode !== '').map(s => s.breastCode.slice(0,2)))]
            return countries.map(country => ({
                groupName: country,
                sites: sites.filter(s => s.breastCode.slice(0,2) === country),
            }))
        }
    }

    const ref = useD3(svg => {
        const formattedData = formatData(data?.dashboard?.patients || [])
        return renderPatientTimeline(svg, formattedData, theme);
    })

    useResizeObserver(ref, () => {
        const formattedData = formatData(data?.dashboard?.patients || [])
        renderPatientTimeline(d3.select(ref.current), formattedData, theme);
    })

    return (
        <DashboardGraph elementRef={ref} title="PATIENT REGISTRATIONS" loading={loading} headerComponent={
            <Stack direction="row" spacing={2} sx={{ mb: 4 }}>
                <MyTextField
                    label="Group by"
                    value={groupBy}
                    onChange={e => setGroupBy(e.target.value)}
                    select
                    >
                    <MenuItem value={MONITORING_GROUP}>Monitoring group</MenuItem>
                    <MenuItem value={COUNTRY}>Country</MenuItem>
                    <MenuItem value={NONE}>None</MenuItem>
                </MyTextField>
                <MyTextField
                    label="Sort by"
                    value={sortBy}
                    onChange={e => setSortBy(e.target.value)}
                    select
                    >
                    <MenuItem value={FIRST_PATIENT}>First patient</MenuItem>
                    <MenuItem value={LAST_PATIENT}>Last patient</MenuItem>
                    <MenuItem value={NUM_PATIENTS}>Number of patients</MenuItem>
                </MyTextField>
            </Stack>
        }/>
    )
}


export const DashboardScreen = () => {
    const [state, dispatch] = useReducer(dashboardReducer, {
        form: {
            icfVersions: ['4.0'],
            activityNames: [...DEFAULT_ACTIVITY_NAMES],
            screeningStatus: [],
        },
    })
    return (
        <Container maxWidth={null}>
          <Card sx={{ mb: 2 }}>
              <CardContent>
                  <FiltersForm state={state} dispatch={dispatch} />
                  <PatientResultCount state={state} dispatch={dispatch} />
              </CardContent>
          </Card>

          <PatientTimeline state={state} dispatch={dispatch} />

          <Grid container spacing={2}>
                <Grid item xs={12} lg={6}><PatientsPerSiteChart state={state} dispatch={dispatch} /></Grid>
                <Grid item xs={12} lg={6}><PatientsPerInvestigatorChart state={state} dispatch={dispatch} /></Grid>
          </Grid>
          <ScreeningFailureReasonsChart state={state} dispatch={dispatch} />

          <AnotherMatrix state={state} dispatch={dispatch} />
        </Container>
    )
}
